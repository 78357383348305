import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useEffect, useReducer, useState } from "react";
import SearchInput from "~/comp/search-input";
import useTitle from "~/hook/title";
import { get } from "../fetch";
import Table from "./table";

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState([]);
  const [result, setResult] = useState([]);
  const [refresh, requestRefresh] = useReducer((x) => !x, false);

  useTitle("LUG NUT");

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const q = new URLSearchParams({ keyword });
        const resp = await get("/nuts/search?" + q.toString());
        setResult(resp.list || []);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [enqueueSnackbar, keyword, refresh]);

  // 搜索
  const onKeywordChange = (value) => {
    setKeyword(value);
  };

  return (
    <Container as="main" maxWidth="full" sx={{ mt: 4, mb: 8 }}>
      <Stack spacing={3} flex={1}>
        <Stack direction="row" spacing={1}>
          <SearchInput
            isLoading={loading}
            onChange={onKeywordChange}
            placeholder="Search by Part Number, Brand, Hex, Seat, Thread, ShankLength, Finish"
            variant="outlined"
            sx={{ width: "100%" }}
          />
        </Stack>
        {result?.length > 0 && (
          <Typography sx={{ px: 1 }} variant="body2" color="gray">
            {result?.length} records in total
          </Typography>
        )}
        <Table result={result} requestRefresh={requestRefresh} />
      </Stack>
    </Container>
  );
}
