import { useEffect, useState } from 'react';
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Stack from '@mui/material/Stack';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import FitbitIcon from '@mui/icons-material/Fitbit';
import Skeleton from '@mui/material/Skeleton';
import { useSnackbar } from 'notistack';
import OutlinedPaper from '~/comp/outlined-paper';
import SearchInput from '~/comp/search-input';
import useTitle from "~/hook/title";
import usePageData from '~/hook/pagedata';
import { post } from '../fetch';
import { format_yuan } from '~/lib/format';
import dayjs from 'dayjs';
require('dayjs/locale/en-ca');

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = usePageData();
  const [keyword, setKeyword] = useState([]);
  const [status, setStatus] = useState('');
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(pageData('rowsPerPage') || 10);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);

  useTitle('My Orders');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const resp = await post('/orders/list', new URLSearchParams({
          page, rows, keyword, status,
        }));
        setList(resp.list || []);
        setCount(resp.count || 0);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    })();
  }, [enqueueSnackbar, page, rows, keyword, status, refresh]);

  // 搜索
  const onKeywordChange = value => {
    setKeyword(value);
    setPage(0);
  }

  // 页面改变
  const onPageChange = (e, newPage) => {
    setPage(newPage);
  }

  // 每页行数改变
  const onRowsPerPageChange = e => {
    const rows = parseInt(e.target.value, 10);

    setRows(rows);
    setPage(0);
    setPageData('rowsPerPage', rows);
  }

  // 状态
  const onStatusChange = (e, v) => {
    if (v !== null) {
      setStatus(v);
      setPage(0);
    }
  }

  return (
    <Container as='main' maxWidth='lg' sx={{ mb: 4 }}>
      <Toolbar sx={{ mt: 2 }} disableGutters>
        <Typography variant='h6' sx={{flex:1}}>My Orders</Typography>
        <Stack direction='row' spacing={2}>
          <SearchInput isLoading={loading} onChange={onKeywordChange}
            placeholder={count > 0 ? `Search in ${count} records...` : 'Search...'}
            sx={{ minWidth: 300 }}
          />
          <ToggleButtonGroup exclusive size='small' color='primary'
            value={status} onChange={onStatusChange}>
            <ToggleButton value='' sx={{ py: '4px' }}>All</ToggleButton>
            <ToggleButton value='1' sx={{ py: '4px' }}>Pending</ToggleButton>
            <ToggleButton value='2' sx={{ py: '4px' }}>Done</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding='checkbox'></TableCell>
            <TableCell align="center">#</TableCell>
            <TableCell align="center">Order No</TableCell>
            <TableCell align="center">Wheels</TableCell>
            <TableCell align="center">Accessories</TableCell>
            <TableCell align="center">Total Amount</TableCell>
            <TableCell align="center">PO#</TableCell>
            <TableCell align="center">Time</TableCell>
            <TableCell align="center" padding='none'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => (
            <Row key={row.uuid} row={row} index={index} />
          ))}
        </TableBody>
        <TableFooter>
          {count > 0 ? (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                colSpan={20}
                count={count}
                rowsPerPage={rows}
                page={page}
                SelectProps={{ inputProps: { 'aria-label': '每页行数' } }}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </TableRow>
          ) : (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell colSpan={30} align='center'>
                {loading ? <Placeholder /> : <Empty />}
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </Container>
  )
}

function Row(props) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wheelCarts, setWheelCarts] = useState([]);
  const [wheelTotal, setWheelTotal] = useState(0);
  const [wheelTax, setWheelTax] = useState(0);
  const [wheelAmount, setWheelAmount] = useState(0);
  const [accessoryCarts, setAccessoryCarts] = useState([]);
  const [accessoryTotal, setAccessoryTotal] = useState(0);
  const [accessoryTax, setAccessoryTax] = useState(0);
  const [accessoryAmount, setAccessoryAmount] = useState(0);

  const { row, index } = props;
  const color = theme.palette.mode === 'dark' ? 'black' : '#eee';

  const onOpen = async () => {
    if (!loaded) {
      try {
        console.log('loading....')
        setLoading(true);

        const resp = await post('/orders/detail', new URLSearchParams({
          uuid: row.uuid,
        }));
        setWheelCarts(resp?.wheel_carts || []);
        setWheelTotal(resp?.wheel_total || 0);
        setWheelTax(resp?.wheel_tax || 0);
        setWheelAmount(resp?.wheel_amount || 0);
        setAccessoryCarts(resp?.accessory_carts || []);
        setAccessoryTotal(resp?.accessory_total || 0);
        setAccessoryTax(resp?.accessory_tax || 0);
        setAccessoryAmount(resp?.accessory_amount || 0);

        setLoaded(true);
        setOpen(!open);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setLoading(false);
      }
    } else {
      setOpen(!open);
    }
  }

  return (
    <>
      <TableRow hover>
        <TableCell padding='checkbox'>
          <Tooltip title='Detail' arrow>
            <IconButton color='primary' size="small" onClick={onOpen}>
              {loading ?
                <CircularProgress size={14} /> :
                open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{row.ordno}</TableCell>
        <TableCell align="center">{format_yuan(row.total1)}</TableCell>
        <TableCell align="center">{format_yuan(row.total2)}</TableCell>
        <TableCell align="center">{format_yuan(row.amount)}</TableCell>
        <TableCell align="center">{row.po}</TableCell>
        <TableCell align="center">
          {dayjs(row.create_at).locale('en-ca').fromNow()}
        </TableCell>
        <TableCell align="center" padding='none'>
          <Stack justifyContent='center'>
            {row.status === 1 &&
              <Tooltip title='Pending' arrow>
                <HourglassTopIcon color='warning' fontSize='small' />
              </Tooltip>
            }
            {row.status === 2 &&
              <Tooltip title='Done' arrow>
                <CheckIcon color='success' fontSize='small' />
              </Tooltip>
            }
            {row.status === 3 &&
              <Tooltip title='About' arrow>
                <CloseIcon color='error' fontSize='small' />
              </Tooltip>
            }
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ backgroundColor: color, padding: 0 }} colSpan={20}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={2}>
              {wheelCarts?.length > 0 &&
                <>
                  <Stack sx={{ my: 2, borderLeft: '6px solid', pl: 2 }}>
                    <Typography variant='h6'>Wheels Info</Typography>
                  </Stack>
                  <WheelsTable wheelCarts={wheelCarts} />
                  <Stack alignItems='flex-end' m={1}>
                    <Stack direction='row' spacing={1} alignItems='baseline'>
                      <Typography variant='body2'>Sub Total:</Typography>
                      <Typography color='red'>
                        $ {format_yuan(wheelTotal)} CAD
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='baseline'>
                      <Typography variant='body2'>Tax:</Typography>
                      <Typography color='red'>
                        $ {format_yuan(wheelTax)} CAD
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='baseline'>
                      <Typography variant='button'>Total Amount:</Typography>
                      <Typography fontSize='large' fontWeight='bold' color='red'>
                        $ {format_yuan(wheelAmount)} CAD
                      </Typography>
                    </Stack>
                  </Stack>
                </>
              }
              {accessoryCarts?.length > 0 &&
                <>
                  <Stack sx={{ my: 2, borderLeft: '6px solid', pl: 2 }}>
                    <Typography variant='h6'>Accessories Info</Typography>
                  </Stack>
                  <AccessoriesTable accessoryCarts={accessoryCarts} />
                  <Stack alignItems='flex-end' m={1}>
                    <Stack direction='row' spacing={1} alignItems='baseline'>
                      <Typography variant='body2'>Sub Total:</Typography>
                      <Typography color='red'>
                        $ {format_yuan(accessoryTotal)} CAD
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='baseline'>
                      <Typography variant='body2'>Tax:</Typography>
                      <Typography color='red'>
                        $ {format_yuan(accessoryTax)} CAD
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems='baseline'>
                      <Typography variant='button'>Total Amount:</Typography>
                      <Typography fontSize='large' fontWeight='bold' color='red'>
                        $ {format_yuan(accessoryAmount)} CAD
                      </Typography>
                    </Stack>
                  </Stack>
                </>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function WheelsTable(props) {
  const { wheelCarts } = props;

  return (
    <TableContainer component={OutlinedPaper}>
      <Table sx={{
        'th:not(:last-child)': {
          borderRight: '1px solid #8884',
        },
        'td:not(:last-child)': {
          borderRight: '1px solid #8884',
        },
      }}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Img</TableCell>
            <TableCell align='center'>Brand</TableCell>
            <TableCell align='center'>P/N</TableCell>
            <TableCell align='center'>Size</TableCell>
            <TableCell align='center'>H/PCD</TableCell>
            <TableCell align='center'>Offset</TableCell>
            <TableCell align='center'>Bore</TableCell>
            <TableCell align='center'>Seat</TableCell>
            <TableCell align='center'>Finish</TableCell>
            <TableCell align='center'>Price</TableCell>
            <TableCell align='center'>Count</TableCell>
            <TableCell align='center'>Subtotal</TableCell>
            <TableCell align='center'>Tax</TableCell>
            <TableCell align='center'>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ 'tr:last-child td': { borderBottom: 0 } }}>
          {wheelCarts?.map(item => (
            <TableRow key={item.uuid}>
              <TableCell align='center'>
                {item.image &&
                  <img alt='' src={`/image/?u=${item.image}`} style={{
                    objectFit: 'contain', width: 40
                  }} />
                }
              </TableCell>
              <TableCell align='center'>{item.logo}</TableCell>
              <TableCell align='center'>{item.pn}</TableCell>
              <TableCell align='center'>{item.diameter}*{item.width}</TableCell>
              <TableCell align='center'>{item.hole}/{item.pcd}</TableCell>
              <TableCell align='center'>{item.et}</TableCell>
              <TableCell align='center'>{item.cb}</TableCell>
              <TableCell align='center'>{item.seat}</TableCell>
              <TableCell align='center'>{item.color}</TableCell>
              <TableCell align='center'>{format_yuan(item.price)}</TableCell>
              <TableCell align='center'>{item.count}</TableCell>
              <TableCell align='center'>{format_yuan(item.total)}</TableCell>
              <TableCell align='center'>{format_yuan(item.tax)}</TableCell>
              <TableCell align='center'>{format_yuan(item.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function AccessoriesTable(props) {
  const { accessoryCarts } = props;

  return (
    <TableContainer component={OutlinedPaper}>
      <Table sx={{
        'th:not(:last-child)': {
          borderRight: '1px solid #8884',
        },
        'td:not(:last-child)': {
          borderRight: '1px solid #8884',
        },
      }}>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Img</TableCell>
            <TableCell align='center'>Brand</TableCell>
            <TableCell align='center'>Model</TableCell>
            <TableCell align='center'>P/N</TableCell>
            <TableCell align='center'>Price</TableCell>
            <TableCell align='center'>Count</TableCell>
            <TableCell align='center'>Subtotal</TableCell>
            <TableCell align='center'>Tax</TableCell>
            <TableCell align='center'>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ 'tr:last-child td': { borderBottom: 0 } }}>
          {accessoryCarts?.map(item => (
            <TableRow key={item.uuid}>
              <TableCell align='center'>
                {item.image &&
                  <img alt='' src={`/image/?u=${item.image}`} style={{
                    objectFit: 'contain', width: 40
                  }} />
                }
              </TableCell>
              <TableCell align='center'>{item.logo}</TableCell>
              <TableCell align='center'>{item.model}</TableCell>
              <TableCell align='center'>{item.pn}</TableCell>
              <TableCell align='center'>{format_yuan(item.price)}</TableCell>
              <TableCell align='center'>{item.count}</TableCell>
              <TableCell align='center'>{format_yuan(item.total)}</TableCell>
              <TableCell align='center'>{format_yuan(item.tax)}</TableCell>
              <TableCell align='center'>{format_yuan(item.amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Empty() {
  return (
    <Stack alignItems='center' p={6} spacing={2}>
      <FitbitIcon sx={{ fontSize: 96, color: '#8882' }} />
      <Typography variant='body2' color='gray'>There are no orders</Typography>
    </Stack>
  )
}

function Placeholder() {
  return (
    <Stack p={2}>
      <Typography variant='h4' width='100%'><Skeleton /></Typography>
      <Typography variant='h4' width='100%'><Skeleton /></Typography>
      <Typography variant='h4' width='100%'><Skeleton /></Typography>
    </Stack>
  )
}
