import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useSnackbar } from 'notistack';
import { post } from "~/lib/rest";

export default function StatusMenu(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const { item, requestRefresh } = props;

  const onClose = () => {
    setAnchorEl(null);
  };

  const onStatusClick = async status => {
    try {
      if (!status) {
        return enqueueSnackbar('请选择', { variant: 'warning' });
      }
      const _audit = `修改订单 ${item.ordno} 状态`;

      await post('/orders/status', new URLSearchParams({
        uuid: item.uuid, status, _audit,
      }));
      enqueueSnackbar("修改成功", { variant: 'success' });
      requestRefresh();
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  };

  return (
    <>
      <IconButton color='primary' onClick={e => { setAnchorEl(e.currentTarget); }}>
        {item.status === 1 &&
          <Tooltip title='Pending' arrow>
            <HourglassTopIcon color='warning' fontSize='small' />
          </Tooltip>
        }
        {item.status === 2 &&
          <Tooltip title='Done' arrow>
            <CheckIcon color='success' />
          </Tooltip>
        }
        {item.status === 3 &&
          <Tooltip title='About' arrow>
            <CloseIcon color='error' />
          </Tooltip>
        }
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <MenuItem onClick={() => onStatusClick(1)}>
          <ListItemIcon>
            <HourglassTopIcon fontSize="small" color='warning' />
          </ListItemIcon>
          <ListItemText>未决</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onStatusClick(2)}>
          <ListItemIcon>
            <CheckIcon fontSize="small" color='success' />
          </ListItemIcon>
          <ListItemText>完成</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => onStatusClick(3)}>
          <ListItemIcon>
            <CloseIcon fontSize="small" color='error' />
          </ListItemIcon>
          <ListItemText>取消</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
