import { atom } from 'recoil';

const memberState = atom({
  key: 'memberState',
  default: null,
});

export default memberState;

const homeTabState = atom({
  key: 'homeTabState',
  default: 'wheels',
});

export { homeTabState }
