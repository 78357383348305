import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  Navigate, useLocation, useNavigate, Link as RouteLink
} from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Container from "@mui/material/Container";
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'notistack';
import isEmail from 'validator/lib/isEmail';
import progressState from "~/state/progress";
import useTitle from "~/hook/title";
import { get, put } from "~/lib/rest";

export default function Modify() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const setProgress = useSetRecoilState(progressState);
  const [provinces, setProvinces] = useState([]);

  useHotkeys('esc', () => { navigate('..'); }, { enableOnTags: ["INPUT"] });
  useTitle('修改客户资料');

  const { register, handleSubmit, control, setValue, formState: {
    errors, isSubmitting
  }} = useForm({
    defaultValues: {
      province: '',
    }
  });

  useEffect(() => {
    (async () => {
      try {
        if (location.state) {
          setProgress(true);

          const resp1 = await get('/member/provinces');
          setProvinces(resp1.provinces || []);

          const params = new URLSearchParams({ uuid: location.state.uuid });
          const resp = await get('/member/profile?' + params.toString());
          setValue("firstname", resp?.profile?.firstname);
          setValue("lastname", resp?.profile?.lastname);
          setValue("email", resp?.profile?.email);
          setValue("company", resp?.profile?.company);
          setValue("tel", resp?.profile?.tel);
          setValue("tel2", resp?.profile?.tel2);
          setValue("fax", resp?.profile?.fax);
          setValue("cell", resp?.profile?.cell);
          setValue("address1", resp?.profile?.address1);
          setValue("address2", resp?.profile?.address2);
          setValue("country", resp?.profile?.country);
          setValue("province", resp?.profile?.province);
          setValue("city", resp?.profile?.city);
          setValue("postal", resp?.profile?.postal);
          setValue("tax", resp?.profile?.tax);
          setValue("tax_rate", resp?.profile?.tax_rate);
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
      }
    })();
  }, [location.state, enqueueSnackbar, setProgress, setValue]);

  const onSubmit = async data => {
    try {
      setProgress(true);

      data.uuid = location.state.uuid;
      data._audit = `修改客户 ${data.firstname} 的资料`;

      await put('/member/modify', new URLSearchParams(data));
      enqueueSnackbar('客户资料更新成功', { variant: 'success' });
      navigate('..', { replace: true });
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setProgress(false);
    }
  }

  if (!location.state?.uuid) {
    return <Navigate to='..' />
  }

  return (
    <Container as='main' maxWidth='md' sx={{ mb: 6 }}>
      <Paper sx={{ px: 4, py: 3, mt: 5 }}>
        <Stack direction='row' alignItems='center' spacing={1} sx={{ mb: 3 }}>
          <IconButton aria-label="返回" component={RouteLink} to='..'>
            <ArrowBackIcon color='primary' />
          </IconButton>
          <Typography variant='h5'>修改客户资料</Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper variant='outlined' sx={{ px: 4, py: 3 }}>
            <Stack spacing={4}>
              <Stack direction='row' spacing={3}>
                <TextField label='First Name' variant='standard' fullWidth
                  required placeholder='First Name'
                  helperText={errors?.firstname?.message}
                  error={errors?.firstname}
                  InputLabelProps={{ shrink: true }}
                  {...register('firstname', {
                    required: "Required",
                    maxLength: {
                      value: 64, message: 'Exceed max length'
                    },
                  })}
                />
                <TextField label='Last Name' variant='standard' fullWidth
                  required placeholder='Last Name'
                  helperText={errors?.lastname?.message}
                  error={errors?.lastname}
                  InputLabelProps={{ shrink: true }}
                  {...register('lastname', {
                    required: "Required",
                    maxLength: {
                      value: 64, message: 'Exceed max length'
                    },
                  })}
                />
              </Stack>
              <TextField label='Email' variant='standard' fullWidth
                required placeholder='Email'
                helperText={errors?.email?.message}
                error={errors?.email}
                InputLabelProps={{ shrink: true }}
                {...register('email', {
                  required: "Required",
                  maxLength: {
                    value: 128, message: 'Exceed max length'
                  },
                  validate: v => isEmail(v) || 'Invalid',
                })}
              />
              <TextField label='Company Name' variant='standard' fullWidth required
                placeholder='Company name'
                helperText={errors?.company?.message}
                error={errors?.company}
                InputLabelProps={{ shrink: true }}
                {...register('company', {
                  required: "Required",
                  maxLength: {
                    value: 128, message: 'Exceed max length'
                  },
                })}
              />
              <Stack direction='row' spacing={3}>
                <TextField label='Telephone' variant='standard' fullWidth
                  required placeholder='Telephone'
                  helperText={errors?.tel?.message}
                  error={errors?.tel}
                  InputLabelProps={{ shrink: true }}
                  {...register('tel', {
                    required: "Required",
                    maxLength: {
                      value: 32, message: 'Exceed max length'
                    },
                  })}
                />
                <TextField label='Fax' variant='standard' fullWidth
                  placeholder='Fax'
                  helperText={errors?.fax?.message}
                  error={errors?.fax}
                  InputLabelProps={{ shrink: true }}
                  {...register('fax', {
                    maxLength: {
                      value: 32, message: 'Exceed max length'
                    },
                  })}
                />
                <TextField label='Cell' variant='standard' fullWidth
                  placeholder='Cell'
                  helperText={errors?.cell?.message}
                  error={errors?.cell}
                  InputLabelProps={{ shrink: true }}
                  {...register('cell', {
                    maxLength: {
                      value: 32, message: 'Exceed max length'
                    },
                  })}
                />
              </Stack>
              <TextField label='Street Address' variant='standard' fullWidth
                required placeholder='Street Address'
                helperText={errors?.address1?.message}
                error={errors?.address1}
                InputLabelProps={{ shrink: true }}
                {...register('address1', {
                  required: "Required",
                  maxLength: {
                    value: 128, message: 'Exceed max length'
                  },
                })}
              />
              <Stack direction='row' spacing={3} mt={2}>
                <TextField label='Country' variant='standard' fullWidth
                  required select defaultValue='Canada'
                  {...register('country', { required: "Required" })}>
                  <MenuItem value='Canada'>Canada</MenuItem>
                </TextField>
                <Controller name="province" control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField label='State or Province' variant='standard' fullWidth
                      required select value={value} onChange={onChange}>
                      {provinces.map(p => (
                        <MenuItem key={p} value={p}>{p}</MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='City' variant='standard' fullWidth
                  required placeholder='City'
                  helperText={errors?.city?.message}
                  error={errors?.city}
                  InputLabelProps={{ shrink: true }}
                  {...register('city', {
                    required: "Required",
                    maxLength: {
                      value: 32, message: 'Exceed max length'
                    },
                  })}
                />
                <TextField label='ZIP/Postal code' variant='standard' fullWidth
                  placeholder='ZIP/Postal code'
                  helperText={errors?.postal?.message}
                  error={errors?.postal}
                  InputLabelProps={{ shrink: true }}
                  {...register('postal', {
                    maxLength: {
                      value: 32, message: 'Exceed max length'
                    },
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='Business# Or Tax ID#' variant='standard' fullWidth
                  required placeholder='Business# Or Tax ID#'
                  helperText={errors?.tax?.message}
                  error={errors?.tax}
                  InputLabelProps={{ shrink: true }}
                  {...register('tax', {
                    required: "Required",
                    maxLength: {
                      value: 128, message: 'Exceed max length'
                    },
                  })}
                />
                <TextField label='Tax Rate' variant='standard' fullWidth
                  required placeholder='百分比，例如 13 代表 13%' type='number'
                  helperText={errors?.tax_rate?.message}
                  error={errors?.tax_rate}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  {...register('tax_rate', {
                    required: "Required",
                  })}
                />
              </Stack>
              <TextField label='Billing Address' variant='standard' fullWidth
                placeholder='Billing Address'
                helperText={errors?.address2?.message}
                error={errors?.address2}
                InputLabelProps={{ shrink: true }}
                {...register('address2', {
                  maxLength: {
                    value: 128, message: 'Exceed max length'
                  },
                })}
              />
              <TextField label='Billing Telephone' variant='standard' fullWidth
                placeholder='Billing Telephone'
                helperText={errors?.tel2?.message}
                error={errors?.tel2}
                InputLabelProps={{ shrink: true }}
                {...register('tel2', {
                  maxLength: {
                    value: 32, message: 'Exceed max length'
                  },
                })}
              />
            </Stack>
          </Paper>
          <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ mt: 4 }}>
            <Button color='secondary' disabled={isSubmitting}
              onClick={() => { navigate('..') }}>
              取消
            </Button>
            <LoadingButton variant='contained' type='submit'
              loading={isSubmitting}>
              提交
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}
