import { useEffect, useState } from 'react';
import Container from "@mui/material/Container";
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { useSnackbar } from 'notistack';
import SearchInput from '~/comp/search-input';
import TimeAgo from '~/comp/timeago';
import ZoomImage from "~/comp/zoomimage";
import useTitle from "~/hook/title";
import usePageData from '~/hook/pagedata';
import { useSetCode } from "~/state/code";
import { get } from '~/lib/rest';
import { format_yuan } from '~/lib/format';

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = usePageData();
  const [keyword, setKeyword] = useState([]);
  const [ptype, setPtype] = useState('');
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(pageData('rowsPerPage') || 10);
  const [loading, setLoading] = useState(false);

  useTitle('购物车');
  useSetCode(400);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const q = new URLSearchParams({ page, rows, keyword, ptype });
        const resp = await get('/carts/list?' + q.toString());
        setList(resp.list || []);
        setCount(resp.count || 0);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [enqueueSnackbar, page, rows, keyword, ptype]);

  // 搜索
  const onKeywordChange = value => {
    setKeyword(value);
    setPage(0);
  }

  // 页面改变
  const onPageChange = (e, newPage) => {
    setPage(newPage);
  }

  // 每页行数改变
  const onRowsPerPageChange = e => {
    const rows = parseInt(e.target.value, 10);

    setRows(rows);
    setPage(0);
    setPageData('rowsPerPage', rows);
  }

  const onPtypeChange = (e, v) => {
    if (v !== null) {
      setPtype(v);
    }
  }
  return (
    <Container as='main' maxWidth='lg' sx={{ mb: 4 }}>
      <Toolbar sx={{ mt: 2 }} disableGutters>
        <Stack direction='row' spacing={2}>
          <SearchInput isLoading={loading} onChange={onKeywordChange}
            placeholder={count > 0 ? `在 ${count} 条记录中搜索...` : '搜索...'}
            sx={{ minWidth: 300 }}
          />
          <ToggleButtonGroup exclusive size='small' color='primary'
            value={ptype} onChange={onPtypeChange}>
            <ToggleButton value='' sx={{ py: '4px' }}>全部</ToggleButton>
            <ToggleButton value='1' sx={{ py: '4px' }}>轮毂</ToggleButton>
            <ToggleButton value='2' sx={{ py: '4px' }}>配件</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding='none'></TableCell>
            <TableCell align="center">客户</TableCell>
            <TableCell align="center">类型</TableCell>
            <TableCell align="center">P/N</TableCell>
            <TableCell align="center">单价</TableCell>
            <TableCell align="center">数量</TableCell>
            <TableCell align="center">小计</TableCell>
            <TableCell align="center">税费</TableCell>
            <TableCell align="center">总额</TableCell>
            <TableCell align="center">时间</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => (
            <TableRow key={index} hover>
              <TableCell align="center" padding='none'>
                {row.image && <ZoomImage src={`/image/?u=${row.image}`} />}
              </TableCell>
              <TableCell align="center">{row.company}</TableCell>
              <TableCell align="center">
                {row.ptype === 1 ? '轮毂' : '配件'}
              </TableCell>
              <TableCell align="center">{row.comment}</TableCell>
              <TableCell align="center">{format_yuan(row.price)}</TableCell>
              <TableCell align="center">{row.count}</TableCell>
              <TableCell align="center">{format_yuan(row.total)}</TableCell>
              <TableCell align="center">{format_yuan(row.tax)}</TableCell>
              <TableCell align="center">{format_yuan(row.amount)}</TableCell>
              <TableCell align="center">
                <TimeAgo time={row.create_at} variant='body2' />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              colSpan={20}
              count={count}
              rowsPerPage={rows}
              page={page}
              SelectProps={{ inputProps: { 'aria-label': '每页行数' } }}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Container>
  )
}
