import { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Backdrop from '@mui/material/Backdrop';
import OutlinedPaper from '~/comp/outlined-paper';
import { useSnackbar } from 'notistack';
import { get } from '../fetch';
import ResultTable from './table';
import { QueryOption } from "./options";
import { Box } from "@mui/system";
import { Button, Paper } from "@mui/material";

export default function Vehicle() {
  const { enqueueSnackbar } = useSnackbar();
  const [makes, setMakes] = useState([]);
  const [make, setMake] = useState(null);
  const [makeBusy, setMakeBusy] = useState(true);
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(null);
  const [yearBusy, setYearBusy] = useState(false);
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(null);
  const [modelBusy, setModelBusy] = useState(false);
  const [modifications, setModifications] = useState([]);
  const [modification, setModification] = useState(null);
  const [modificationBusy, setModificationBusy] = useState(false);
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [result, setResult] = useState([]);
  const [params, setParams] = useState(null);
  const [refresh, setRefresh] = useState(true);
  // 条件过滤
  const [brand, setBrand] = useState("-1");
  const [model2, setModel2] = useState("-1");
  const [diameter, setDiameter] = useState("-1");
  const [width, setWidth] = useState("-1");
  const [cb, setCb] = useState("-1");
  const [color, setColor] = useState("-1");
  const [list, setList] = useState([]);


  const requestRefresh = () => {
    setRefresh(true);
  }

  useEffect(() => {
    (async () => {
      try {
        if (refresh) {
          const resp = await get('/wheel/vehicle/makes');
          setMakes(resp.list || []);
          setYears([]);
          setYear(null);
          setModels([]);
          setModel(null);
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setMakeBusy(false);
        setRefresh(false);
      }
    })();
  }, [enqueueSnackbar, refresh]);

  const onMakeChange = async (e, v) => {
    try {
      resetData();
      setYearBusy(true);

      setYears([]);
      setYear(null);
      setModels([]);
      setModel(null);
      setModifications([]);
      setModification(null);

      const q = new URLSearchParams({ make: v.slug });
      const resp = await get('/wheel/vehicle/years?' + q.toString());
      setYears(resp.list || []);
      setMake(v);
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setYearBusy(false);
    }
  }

  const onYearChange = async (e, v) => {
    try {
      resetData();
      setModelBusy(true);

      setModels([]);
      setModel(null);
      setModifications([]);
      setModification(null);

      const q = new URLSearchParams({ make: make.slug, year: v.slug });
      const resp = await get('/wheel/vehicle/models?' + q.toString());
      setModels(resp.list || []);
      setYear(v);
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setModelBusy(false);
    }
  }

  const onModelChange = async (e, v) => {
    try {
      resetData();
      setModificationBusy(true);

      setModifications([]);
      setModification(null);

      const q = new URLSearchParams({
        make: make.slug, year: year.slug, model: v.slug
      });
      const resp = await get('/wheel/vehicle/modifications?' + q.toString());
      setModifications(resp.list || []);
      setModel(v);
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setModificationBusy(false);
    }
  }

  const onModificationChange = async (e, v) => {
      try {
      resetData();
      setBackdropOpen(true);

      const q = new URLSearchParams({
        make: make.slug, year: year.slug, model: model.slug, modification: v.slug,
      });
      const resp = await get('/wheel/vehicle/search?' + q.toString());
      setResult(resp.list || []);
      setParams(resp.params);
      setModification(v);
      // reset options
      resetOptions();
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setBackdropOpen(false);
    } 
  }

  function resetData() {
    setList([]);
    setResult([]);
    setParams(null);
    resetOptions();
  }

  function resetOptions() {
    setBrand("-1");
    setModel2("-1");
    setDiameter("-1");
    setWidth("-1");
    setCb("-1");
    setColor("-1");
  }

  useEffect(()=>{
    if (result.length === 0) return

    let r = [...result]

    if (width !== "-1") {
      r = r.filter((item ) => item.width === width)
    }
    if (diameter !== "-1") {
      r = r.filter((item ) => item.diameter === diameter)
    }
    if (cb !== "-1") {
      r = r.filter((item ) => item.cb === cb)
    }
    if (color !== "-1") {
      r = r.filter((item ) => item.color === color)
    }
    if (model2 !== "-1") {
      r = r.filter((item ) => item.model === model2)
    }
    if (brand !== "-1") {
      r = r.filter((item ) => item.logo === brand)
    }

    setList(r)
  }, [brand, cb, color, diameter, model2, result, width])

  return (
    <Stack spacing={3} flex={1}>

      <Stack direction='row' spacing={1}>
        <Autocomplete sx={{ width: 240 }} options={makes} disableClearable
          getOptionLabel={opt => opt?.name || ''}
          value={make} onChange={onMakeChange}
          isOptionEqualToValue={(opt, v) => {
            return opt.slug === v?.slug;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Make" size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  makeBusy ? <CircularProgress color="secondary" size={16} /> : null
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <Stack key={option.slug} {...props}
              direction='row' spacing={2} my={1} alignItems='center'>
              <img src={option.logo} style={{ width: 40 }} alt='' />
              <Typography>{option.name}</Typography>
            </Stack>
          )}
        />
        <Autocomplete sx={{ width: 140 }} options={years} disableClearable
          getOptionLabel={opt => opt?.name.toString() || ''}
          value={year} onChange={onYearChange}
          isOptionEqualToValue={(opt, v) => {
            return opt.slug === v?.slug;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Year" size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  yearBusy ? <CircularProgress color="secondary" size={16} /> : null
                ),
              }}
            />
          )}
        />
        <Autocomplete sx={{ width: 260 }} options={models} disableClearable
          getOptionLabel={opt => opt?.name || ''}
          value={model} onChange={onModelChange}
          isOptionEqualToValue={(opt, v) => {
            return opt.slug === v?.slug;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Model" size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  modelBusy ? <CircularProgress color="secondary" size={16} /> : null
                ),
              }}
            />
          )}
        />
        <Autocomplete sx={{ width: 360 }} options={modifications} disableClearable
          getOptionLabel={opt => opt ? 
            `${opt.name} (${opt.generation.name})` 
            : ''}
          value={modification} onChange={onModificationChange}
          isOptionEqualToValue={(opt, v) => {
            return opt.slug === v?.slug;
          }}
          renderOption={(props, option, { selected }) => (
            <Box
              component="li"
              // sx={{ "& > img": { mr: 2, flexShrink: 0 }, minWidth: 600 }}
              {...props}
              key={option.slug}
            >
              <Stack direction="row"  sx={{ width: "100%" }}>
                <Typography textAlign="left" sx={{ flex: 1 }}>
                  {option.name}
                </Typography>
                <Typography color="gray" sx={{ ml: 2 }}>
                  {option.generation.name}
                </Typography>
              </Stack>
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Trim" size="small"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  modificationBusy ? <CircularProgress color="secondary" size={16} /> : null
                ),
              }}
            />
          )}
        />
      </Stack>
      <TableContainer component={OutlinedPaper}>
        <Table size='small'>
          <TableBody sx={{
            'td:not(:last-child)': { borderRight: '1px solid #8884' },
            'tr:last-child td': { borderBottom: 0 }
          }}>
            <TableRow>
              <TableCell align="center">PCD</TableCell>
              <TableCell align="center">Bore</TableCell>
              <TableCell align="center">Thread</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Direct TPMS?</TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center">{params?.holes && params?.pcd && `${params?.holes} x ${params?.pcd}`}</TableCell>
              <TableCell align="center">{params?.bore}</TableCell>
              <TableCell align="center">{params?.thread}</TableCell>
              <TableCell align="center">{params?.type}</TableCell>
              <TableCell align="center">{params?.type ? "No" : <Box sx={{minHeight: 32}}></Box>}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Paper sx={{p:1}} variant="elevation" elevation={6}>
        <Stack direction="row" >
          <Stack direction="row" spacing={1} sx={{flex:1}}>
            <QueryOption list={list} field="logo" label="Brand" value={brand} onChange={(v)=>setBrand(v)}/>
            <QueryOption list={list} field="model" label="Model" value={model2} onChange={(v)=>setModel2(v)}/>
            <QueryOption list={list} field="diameter" label="Diameter" value={diameter} onChange={(v)=>setDiameter(v)}/>
            <QueryOption list={list} field="width" label="Width" value={width} onChange={(v)=>setWidth(v)}/>
            <QueryOption list={list} field="cb" label="Bore" value={cb} onChange={(v)=>setCb(v)}/>
            <QueryOption list={list} field="color" label="Finish" value={color} onChange={(v)=>setColor(v)}/>
            <Button variant="standard" sx={{px: 4}} onClick={()=>resetOptions()}>Reset</Button>
          </Stack>

          <Stack direction="row" spacing={3} sx={{alignItems: "center"}}>
          <Typography sx={{ px: 1, ml:4, justifyItems: 'end' }} variant='body2' colorx='gray' >
            {list?.length} records in total
          </Typography>
          </Stack>
        </Stack>
      </Paper>
      <ResultTable result={list} requestRefresh={requestRefresh} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen} onClick={() => setBackdropOpen(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Stack>
  )
}
