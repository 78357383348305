import { Button, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from "@mui/material/Stack";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import SearchInput from '~/comp/search-input';
import { get } from '../fetch';
import { QueryOption } from "./options";
import Table from './table';

export default function Spec() {
  const { enqueueSnackbar } = useSnackbar();
  // const [params, setParams] = useState({});
  const [hole, setHole] = useState('-1');
  const [pcd, setPCD] = useState('-1');
  const [diameter, setDiameter] = useState('-1');
  const [width, setWidth] = useState('-1');
  const [bore, setBore] = useState('-1');
  const [color, setColor] = useState('-1');
  const [model, setModel] = useState('-1');
  const [brand, setBrand] = useState('-1');
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [result, setResult] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState([]);

  const requestRefresh = () => {
    setRefresh(true);
  }

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (refresh) {
  //         // const resp = await get('/wheel/spec/params');
  //         // setParams(resp.params);
  //       }
  //     } catch (err) {
  //       enqueueSnackbar(err.message);
  //     } finally {
  //       setRefresh(false);
  //     }
  //   })();
  // }, [enqueueSnackbar, refresh]);

  useEffect(() => {
    (async () => {
      try {
        // const q = new URLSearchParams({
          // pcd, diameter, width, bore, color, model, brand,
        // });
        // const resp = await get('/wheel/spec/search?' + q.toString());

        setBackdropOpen(true);
        setLoading(true);

        const q = new URLSearchParams({ keyword });
        const resp = await get('/wheel/detail/search?' + q.toString());
        setResult(resp.list || []);
        resetOptions();
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setBackdropOpen(false);
        setLoading(false);
      }
    })();
  }, [enqueueSnackbar, keyword]);

  function resetOptions() {
    setBrand("-1");
    setModel("-1");
    setDiameter("-1");
    setWidth("-1");
    setBore("-1");
    setColor("-1");
    setHole("-1");
    setPCD("-1");
  }

  useEffect(()=>{
    // 本地过滤
    if (result.length === 0) return

    let r = [...result]

    if (width !== "-1") {
      r = r.filter((item ) => item.width === width)
    }
    if (diameter !== "-1") {
      r = r.filter((item ) => item.diameter === diameter)
    }
    if (bore !== "-1") {
      r = r.filter((item ) => item.cb === bore)
    }
    if (color !== "-1") {
      r = r.filter((item ) => item.color === color)
    }
    if (model !== "-1") {
      r = r.filter((item ) => item.model === model)
    }
    if (brand !== "-1") {
      r = r.filter((item ) => item.logo === brand)
    }
    if (hole !== "-1") {
      r = r.filter((item ) => item.hole === hole)
    }
    if (pcd !== "-1") {
      r = r.filter((item ) => item.pcd === pcd)
    }

    setList(r)
  }, [bore, brand, color, diameter, hole, model, pcd, result, width])


  // 搜索
  const onKeywordChange = value => {
    setKeyword(value);
  }

  return (
    <Stack spacing={3} flex={1}>

      <Stack direction="row" spacing={1} sx={{flex:1}}>
        <QueryOption list={list} field="logo" label="Brand" value={brand} onChange={(v)=>setBrand(v)}/>
        <QueryOption list={list} field="model" label="Model" value={model} onChange={(v)=>setModel(v)}/>
        <QueryOption list={list} field="pcd" label="PCD" value={pcd} onChange={(v)=>setPCD(v)}/>
        <QueryOption list={list} field="hole" label="Holes" value={hole} onChange={(v)=>setHole(v)}/>
        <QueryOption list={list} field="diameter" label="Diameter" value={diameter} onChange={(v)=>setDiameter(v)}/>
        <QueryOption list={list} field="width" label="Width" value={width} onChange={(v)=>setWidth(v)}/>
        <QueryOption list={list} field="cb" label="Bore" value={bore} onChange={(v)=>setBore(v)}/>
        <QueryOption list={list} field="color" label="Finish" value={color} onChange={(v)=>setColor(v)}/>
        <Button variant="standard" sx={{px: 4}} onClick={()=>resetOptions()}>Reset</Button>
      </Stack>

      <Stack direction='row' spacing={1}>
        <SearchInput isLoading={loading} onChange={onKeywordChange}
          placeholder='Search by Part Number, PCD, OFFSET, Bore, Brand, Finish'
          variant='outlined' sx={{ width: '100%' }}
        />
      </Stack>

      <Stack direction="row" spacing={3} sx={{alignItems: "center"}}>
        <Typography sx={{ px: 1, ml:0, justifyItems: 'end' }} variant='body2' colorx='gray' >
          {list?.length} records in total
        </Typography>
      </Stack>
      <Table result={list} requestRefresh={requestRefresh} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen} onClick={() => setBackdropOpen(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Stack>
  )
}
