import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import ReactImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './image-gallery.css';

export default function ImageGallery(props) {
  const [popup, setPopup] = useState(false);
  const [index, setIndex] = useState(0);

  const [ image1, image2, image3 ] = props.images;

  const items = [];

  if (image1) {
    items.push({
      thumbnail: `/image/?u=${image1}&thumb=true`,
      original: `/image/?u=${image1}`,
      originalHeight: 600,
    });
  }
  if (image2) {
    items.push({
      thumbnail: `/image/?u=${image2}&thumb=true`,
      original: `/image/?u=${image2}`,
      originalHeight: 600,
    });
  }
  if (image3) {
    items.push({
      thumbnail: `/image/?u=${image3}&thumb=true`,
      original: `/image/?u=${image3}`,
      originalHeight: 600,
    });
  }

  const handleClose = () => {
    setPopup(false);
  };

  const onBackdropClick = e => {
    if (e.target?.classList[0] === 'MuiBackdrop-root') {
      handleClose();
    }
  }

  const onImageClick = index => {
    setIndex(index);
    setPopup(true);
  }

  // if (items.length === 1) {
  //   return <ZoomImage src={items[0].original} />
  // }

  return (
    <>
      <Stack direction='row' spacing={'4px'}>
        {items.map((item, index) => (
          <Image key={item.thumbnail} src={item.thumbnail} index={index}
            onClick={onImageClick}
          />
        ))}
      </Stack>
      <Backdrop open={popup} onClick={onBackdropClick} sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
      }}>
        {popup &&
        <ReactImageGallery items={items} showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
          showBullets startIndex={index}
          renderCustomControls={() => (
            <IconButton color='error' size='large' onClick={handleClose} sx={{
              position: 'absolute', right: 0, top: 0, zIndex: 4,
            }}>
              <CloseIcon />
            </IconButton>
          )}
        />
        }
      </Backdrop>
    </>
  )
}

function Image(props) {
  const { src, index, onClick } = props;

  return (
    <img alt={props.alt || ''} src={src}
      onClick={() => onClick(index)}
      style={{
        objectFit: 'contain', height: 48, width: 48, borderRadius: 4, cursor: 'zoom-in',
      }}
    />
  )
}
