import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PhotoCameraBackOutlinedIcon from '@mui/icons-material/PhotoCameraBackOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSecretCode } from '~/comp/secretcode';
import { del } from '~/lib/rest';

export default function MoreMenu(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const secretCode = useSecretCode();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const { item, requestRefresh } = props;

  const onClose = () => {
    setAnchorEl(null);
  };

  // 资料
  const onProfileClick = () => {
    navigate('profile', { state: { uuid: item.uuid } });
  };

  // 修改资料
  const onModifyClick = () => {
    navigate('modify', { state: { uuid: item.uuid } });
  };

  // 删除
  const onDeleteClick = async () => {
    try {
      await confirm({
        description: `确定要删除螺母 ${item.pn} 吗？`,
        confirmationText: '删除',
        confirmationButtonProps: { color: 'error' },
      });
      const token = await secretCode();

      const _audit = `删除螺母 ${item.pn}`;

      const params = new URLSearchParams({
        uuid: item.uuid, secretcode_token: token, _audit,
      });
      await del('/nuts/del?' + params.toString());
      enqueueSnackbar('已删除', { variant: 'success' });
      requestRefresh();
    } catch (err) {
      if (err) {
        enqueueSnackbar(err.message);
      }
    }
  }

  return (
    <>
      <IconButton color='primary'
        aria-label='菜单'
        aria-controls={open ? '菜单' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={e => { setAnchorEl(e.currentTarget); }}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <MenuItem onClick={onProfileClick}>
          <ListItemIcon>
            <ArticleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>详细资料</ListItemText>
        </MenuItem>
        <MenuItem disabled={item.disabled || item.deleted} onClick={onModifyClick}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>修改资料</ListItemText>
        </MenuItem>
        <MenuItem component='label' disabled={item.deleted} htmlFor={item.uuid + 1}>
          <ListItemIcon>
            <PhotoCameraBackOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>修改图片</ListItemText>
        </MenuItem>
        {/* <MenuItem component='label' disabled={item.deleted} htmlFor={item.uuid + 2}>
          <ListItemIcon>
            <PhotoCameraBackOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>修改图片2</ListItemText>
        </MenuItem>
        <MenuItem component='label' disabled={item.deleted} htmlFor={item.uuid + 3}>
          <ListItemIcon>
            <PhotoCameraBackOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>修改图片3</ListItemText>
        </MenuItem> */}
        <Divider />
        <MenuItem disabled={item.deleted} onClick={onDeleteClick}>
          <ListItemIcon>
            <RemoveCircleOutlineIcon fontSize="small" color='error' />
          </ListItemIcon>
          <ListItemText>删除</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
