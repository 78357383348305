import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import useTitle from "~/hook/title";
import { put } from './fetch';

export default function Password() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useTitle('Change password');

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

  // 提交
  const onSubmit = async data => {
    try {
      if (data.new !== data.new2) {
        return enqueueSnackbar('New password dismatch, please check.');
      }
      await put('/passwd', new URLSearchParams(data));
      enqueueSnackbar('Password has been changed', { variant: 'success' });
      navigate('..', { replace: true });
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  }

  return (
    <Container as='main' maxWidth='sm' sx={{ mb: 8 }}>
      <Paper elevation={3} sx={{ px: 5, py: 4, mt: 5 }}>
        <Stack direction='row' alignItems='center' spacing={1} mb={2}>
          <Typography variant='h4'>Change password</Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} mt={3}>
            <TextField label='Old Password' fullWidth autoFocus required
              type='password'
              helperText={errors?.old?.message}
              error={errors?.old}
              InputLabelProps={{ shrink: true }}
              {...register('old', {
                required: "Required",
                maxLength: {
                  value: 128, message: 'Exceed max length'
                },
              })}
            />
            <TextField label='New Password' fullWidth required
              type='password' autoComplete='new-password'
              helperText={errors?.new?.message}
              error={errors?.new}
              InputLabelProps={{ shrink: true }}
              {...register('new', {
                required: "Required",
                maxLength: {
                  value: 128, message: 'Exceed max length'
                },
              })}
            />
            <TextField label='Confirm New Password' fullWidth required
              type='password' autoComplete='new-password'
              helperText={errors?.new2?.message}
              error={errors?.new2}
              InputLabelProps={{ shrink: true }}
              {...register('new2', {
                required: "Required",
                maxLength: {
                  value: 128, message: 'Exceed max length'
                },
              })}
            />
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ mt: 5 }}>
            <LoadingButton variant='contained' type='submit' color='info'
              loading={isSubmitting}>
              Change
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}
