import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { Link as RouteLink, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useSetCode } from "~/state/code";
import progressState from "~/state/progress";

export default function EditForm(props) {
  const [progress, setProgress] = useRecoilState(progressState);
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { entity, mode = "update", onSubmit } = props;

  useHotkeys(
    "esc",
    () => {
      navigate("..");
    },
    { enableOnTags: ["INPUT"] }
  );
  useSetCode(221);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm();

  const reset = useCallback(
    (info) => {
      setValue("brand", info.brand);
      setValue("pn", info.pn);
      setValue("hex", info.hex);
      setValue("seat", info.seat);
      setValue("thread", info.thread);
      setValue("shanklength", info.shanklength);
      setValue("finish", info.finish);
      setValue("eta", info.eta);
      setValue("price", info.price);
      setValue("quantity", info.quantity);

      clearErrors();
    },
    [setValue, clearErrors]
  );

  useEffect(() => {
    if (mode !== "new" && !location.state?.uuid) {
      enqueueSnackbar("uuid 不存在", { variant: "error" });
      navigate("..");
    }
    // onMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 获取数据
    if (mode !== "new" && entity != null) {
      reset(entity);
    }
  }, [entity, mode, reset]);

  return (
    <Container as="main" maxWidth="md" sx={{ mb: 4 }}>
      <Paper sx={{ px: 4, py: 3, mt: 5 }}>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 3 }}>
          <IconButton aria-label="返回" component={RouteLink} to="..">
            <Tooltip arrow title="ESC" placement="top">
              <ArrowBackIcon color="primary" />
            </Tooltip>
          </IconButton>
          <Typography variant="h5">螺母资料</Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper variant="outlined" sx={{ px: 4, py: 3 }}>
            <Stack spacing={4}>
              <Stack direction="row" spacing={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Brand"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="Brand"
                  disabled={isSubmitting}
                  helperText={errors?.brand?.message}
                  error={errors?.brand ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register("brand", {
                    required: "不能为空",
                    maxLength: {
                      value: 64,
                      message: "超出最大长度",
                    },
                  })}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="PN"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="PN"
                  disabled={isSubmitting}
                  helperText={errors?.pn?.message}
                  error={errors?.pn ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register("pn", {
                    required: "不能为空",
                    maxLength: {
                      value: 64,
                      message: "超出最大长度",
                    },
                  })}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Hex"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="Hex"
                  disabled={isSubmitting}
                  helperText={errors?.hex?.message}
                  error={errors?.hex ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register("hex", {
                    required: "不能为空",
                    maxLength: {
                      value: 64,
                      message: "超出最大长度",
                    },
                  })}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Seat"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="Seat"
                  disabled={isSubmitting}
                  helperText={errors?.seat?.message}
                  error={errors?.seat ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register("seat", {
                    required: "不能为空",
                    maxLength: {
                      value: 64,
                      message: "超出最大长度",
                    },
                  })}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Thread"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="Thread"
                  disabled={isSubmitting}
                  helperText={errors?.thread?.message}
                  error={errors?.thread ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register("thread", {
                    required: "不能为空",
                    maxLength: {
                      value: 64,
                      message: "超出最大长度",
                    },
                  })}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Shank Length"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="Shank Length"
                  disabled={isSubmitting}
                  helperText={errors?.shanklength?.message}
                  error={errors?.shanklength ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register("shanklength", {
                    required: "不能为空",
                    maxLength: {
                      value: 64,
                      message: "超出最大长度",
                    },
                  })}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="Finish"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="Finish"
                  disabled={isSubmitting}
                  helperText={errors?.finish?.message}
                  error={errors?.finish ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register("finish", {
                    required: "不能为空",
                    maxLength: {
                      value: 64,
                      message: "超出最大长度",
                    },
                  })}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="ETA"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="ETA"
                  disabled={isSubmitting}
                  helperText={errors?.eta?.message}
                  error={errors?.eta ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register("eta", {
                    required: "不能为空",
                    maxLength: {
                      value: 64,
                      message: "超出最大长度",
                    },
                  })}
                />
              </Stack>
              <Stack direction="row" spacing={3}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="价格(Price)"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="指导价格"
                  type="number"
                  disabled={isSubmitting}
                  helperText={errors?.price?.message}
                  error={errors?.price ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  {...register("price", {
                    required: "不能为空",
                  })}
                />

                <TextField
                  InputLabelProps={{ shrink: true }}
                  label="库存(Quantity)"
                  variant="standard"
                  fullWidth
                  required
                  placeholder="Quantity"
                  type="number"
                  disabled={isSubmitting}
                  helperText={errors?.quantity?.message}
                  error={errors?.quantity ? true : false}
                  inputProps={{ min: 0 }}
                  {...register("quantity", {
                    required: "不能为空",
                  })}
                />
              </Stack>
            </Stack>
          </Paper>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: 4 }}
          >
            <Button
              color="secondary"
              disabled={isSubmitting}
              onClick={() => {
                navigate("..");
              }}
            >
              取消
            </Button>
            <Button
              disabled={isSubmitting}
              onClick={() => {
                reset(entity);
              }}
            >
              重置
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              提交
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}
