import { useEffect, useState } from "react";
import {
  Navigate, useLocation, useNavigate, Link as RouteLink
} from "react-router-dom";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'notistack';
import useTitle from "~/hook/title";
import { post } from "~/lib/rest";

export default function Edit() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState('');

  useHotkeys('esc', () => { navigate('..'); }, { enableOnTags: ["INPUT"] });
  useTitle('修改订单');

  useEffect(() => {
    setStatus(location.state?.status);
  }, [location.state]);

  const onStatusChange = e => {
    setStatus(e.target.value);
  }

  const onSubmit = async () => {
    try {
      if (!status) {
        return enqueueSnackbar('请选择', { variant: 'warning' });
      }
      await post('/orders/status', new URLSearchParams({
        uuid: location.state?.uuid, status
      }));
      enqueueSnackbar("修改成功", { variant: 'success' });
      navigate('..');
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  }

  if (!location.state?.uuid) {
    return <Navigate to='..' />
  }

  return (
    <Container as='main' maxWidth='sm' sx={{ mb: 6 }}>
      <Paper sx={{ px: 4, py: 3, mt: 5 }}>
        <Stack direction='row' alignItems='center' spacing={1} sx={{ mb: 3 }}>
          <IconButton aria-label="返回" component={RouteLink} to='..'>
            <ArrowBackIcon color='primary' />
          </IconButton>
          <Typography variant='h5'>修改订单</Typography>
        </Stack>
        <TextField required fullWidth select label="状态"
          value={status} onChange={onStatusChange}>
          <MenuItem value={1}>未决</MenuItem>
          <MenuItem value={2}>完成</MenuItem>
          <MenuItem value={3}>取消</MenuItem>
        </TextField>
        <Stack mt={4} alignItems='flex-end'>
          <Button variant='contained' onClick={onSubmit}>修改</Button>
        </Stack>
      </Paper>
    </Container>
  )
}
