import AcUnitIcon from '@mui/icons-material/AcUnit';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CameraIcon from '@mui/icons-material/Camera';
import CampaignIcon from '@mui/icons-material/Campaign';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const menus = [{
  title: '业务管理',
  items: [{
    code: 100,
    icon: PersonIcon,
  }, {
    code: 200,
    icon: CameraIcon,
  }, {
    code: 220,
    icon: DashboardOutlinedIcon,
  }, {
    code: 221,
    icon: DashboardOutlinedIcon,
  }, {
    code: 222,
    icon: DashboardOutlinedIcon,
  }, {
    code: 300,
    icon: ReceiptLongIcon,
  }, {
    code: 400,
    icon: ShoppingCartIcon,
  }],
}, {
  title: '系统管理',
  items: [{
    code: 9000,
    icon: PersonIcon,
  }, {
    code: 9010,
    icon: SecurityIcon,
  }, {
    code: 9020,
    icon: HistoryIcon,
  }, {
    code: 9025,
    icon: AppRegistrationIcon,
  }, {
    code: 9030,
    icon: ReportGmailerrorredIcon,
  }, {
    code: 9040,
    icon: SettingsIcon,
  }, {
    code: 9050,
    icon: AccessAlarmIcon,
  }, {
    code: 9060,
    icon: AcUnitIcon,
  }, {
    code: 9070,
    icon: CampaignIcon,
  }],
}]

export default menus;
