import { MenuItem, TextField, Typography, Stack } from "@mui/material";
import { use } from "marked";
import { useEffect, useState } from "react";


function distinctAndOrderBy(list, field) {
  // console.log("list=", field, list )
  return list.map((a) => a[field])
    .filter((a, i, arr) => arr.indexOf(a) === i)
    .sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
}


// value = value : string
// onChange = (value) => {}
// 选择全部: "all"
export const QueryOption = ({ 
  onChange = ()=>{}, 
  list, 
  field, 
  label,
  value,
  ...props 
}) => {
  const options = distinctAndOrderBy(list, field)
  // console.log("options=", options)
  return (
    <TextField
      select
      label={label}
      value={value}
      size="small"
      // variant="standard"
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        onChange: (e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        },
      }}
      sx={{minWidth: 120}}
      {...props}
    >
      <MenuItem key="key-the-0" value="-1">
        <Typography variant="body2">{"All"}</Typography>
      </MenuItem>
      {options.map((a, i) => (
        <MenuItem key={`${field}-${i}`} value={a}>

          <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
            <Typography textAlign="left" sx={{ flex: 1 }}>
              {a}
            </Typography>
            {/* <Typography color="gray" sx={{ ml: 2 }}>
              {a.idx}
            </Typography> */}
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  );
};

