import { useState } from "react";
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { format_yuan } from '~/lib/format';
import memberState from '../state';
import { put } from '../fetch';

export default function Order(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [member, setMember] = useRecoilState(memberState);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState('');
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [amount, setAmount] = useState(0);
  const [width, setWidth] = useState(4);
  const [submitting, setSubmitting] = useState(false);

  const { item, disabled, requestRefresh } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCountChange = e => {
    const v = e.target.value;
    setCount(v);

    const sum = Math.round(item.discount_price * v * 100) / 100;
    const tax = Math.round(sum * member.tax_rate) / 100;
    const amt = Math.round((sum + tax) * 100) / 100;
    setTotal(sum);
    setTax(tax);
    setAmount(amt);

    if (amt >= 1000 * 1000 * 1000) {
      setWidth(6);
    } else if (amt >= 1000 * 1000) {
      setWidth(5);
    } else {
      setWidth(4);
    }
  }

  const onAddCart = async () => {
    try {
      if (count < 1) {
        return enqueueSnackbar('Please enter the count', { variant: 'info' });
      }
      if (count > item.quantity) {
        return enqueueSnackbar('Out of stock', { variant: 'info' });
      }
      setSubmitting(true);

      const resp = await put('/cart/add', new URLSearchParams({
        ptype: 1, pid: item.uuid, price: item.discount_price, count, pn: item.pn,
      }));
      enqueueSnackbar('Please check your cart', { variant: 'success' });
      handleClose();

      if (resp.add) {
        const m = { ...member };
        m.cart_count = (m?.cart_count || 0) + 1;
        setMember(m);
      }
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <IconButton onClick={handleClickOpen} color='info' disabled={disabled}>
        <Tooltip title='Add to cart' arrow>
          <AddShoppingCartIcon />
        </Tooltip>
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
        <DialogContent>
          <Stack direction='row'>
            <Stack spacing={1}>
              {item.image && <img alt='' src={`/image/?u=${item.image}`}
                style={{ objectFit: 'contain', width: 380, borderRadius: 10 }}
              />}
              <Grid container spacing={1} minWidth={1} maxWidth={380}>
                <Grid item xs={4}>
                  <Typography variant='button'>Size:</Typography>
                </Grid>
                <Grid item xs={8}>{item.diameter}x{item.width}</Grid>
                <Grid item xs={4}>
                  <Typography variant='button'>H/PCD:</Typography>
                </Grid>
                <Grid item xs={8}>{item.hole}x{item.pcd}</Grid>
                <Grid item xs={4}>
                  <Typography variant='button'>Offset:</Typography>
                </Grid>
                <Grid item xs={8}>{item.et}</Grid>
                <Grid item xs={4}>
                  <Typography variant='button'>Bore:</Typography>
                </Grid>
                <Grid item xs={8}>{item.cb}</Grid>
                <Grid item xs={4}>
                  <Typography variant='button'>Finish:</Typography>
                </Grid>
                <Grid item xs={8}>{item.color}</Grid>
                <Grid item xs={4}>
                  <Typography variant='button'>Attention:</Typography>
                </Grid>
                <Grid item xs={8} sx={{wordBreak: 'break-word'}}>
                  {item.attention}
                </Grid>
              </Grid>
            </Stack>
            <Divider orientation="vertical" flexItem sx={{ mx: 3 }} />
            <Stack flex={1}>
              <Stack flex={1} spacing={3}>
                <Typography variant='h4'>Add to cart</Typography>
                <Stack>
                  <Stack direction='row' spacing={1} alignItems='baseline'>
                    <Typography variant='body2'>Price per unit:</Typography>
                    <Typography sx={{ textDecoration: 'line-through' }}>
                      ${format_yuan(item.price)}
                    </Typography>
                    <Stack direction='row' alignItems='baseline'>
                      <Typography fontSize='large' color='green'>
                        ${format_yuan(item.discount_price)}
                      </Typography>
                      <Typography variant="body2" fontWeight='500'>
                        &nbsp;CAD
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction='row' spacing={1} alignItems='baseline'>
                    <Typography variant='body2'>Quantity:</Typography>
                    <Typography>
                      {item.quantity >= 40 ? '40+' : item.quantity > 0 ? item.quantity : 0}
                    </Typography>
                  </Stack>
                </Stack>
                <TextField label='Count' fullWidth autoFocus required
                  placeholder='Order count' type='number' focused
                  value={count} onChange={onCountChange}
                  inputProps={{ min: 1, max: item.quantity }}
                />
                <Stack>
                  <Grid container spacing={1} alignItems='baseline'>
                    <Grid item xs={12-width}>
                      <Typography variant="body2" align="right">Sub Total:</Typography>
                    </Grid>
                    <Grid item xs={width}>
                      <Stack direction='row' alignItems='baseline' justifyContent='flex-end'>
                        <Typography variant="body2" align="right">
                          ${format_yuan(total)}
                        </Typography>
                        <Typography variant="body2" fontWeight='500'>
                          &nbsp;CAD
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12-width}>
                      <Typography variant="body2" align="right">Tax:</Typography>
                    </Grid>
                    <Grid item xs={width}>
                      <Stack direction='row' alignItems='baseline' justifyContent='flex-end'>
                        <Typography variant="body2" align="right">
                          ${format_yuan(tax)}
                        </Typography>
                        <Typography variant="body2" fontWeight='500'>
                          &nbsp;CAD
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12-width}>
                      <Typography variant="subtitle2" align="right">Total Amount:</Typography>
                    </Grid>
                    <Grid item xs={width}>
                      <Stack direction='row' alignItems='baseline' justifyContent='flex-end'>
                        <Typography align="right" color='orangered' fontSize='large'>
                          ${format_yuan(amount)}
                        </Typography>
                        <Typography variant="body2" fontWeight='500'>
                          &nbsp;CAD
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <Stack direction='row' justifyContent='flex-end' spacing={3}>
                  <Button variant='contained' color='info' onClick={onAddCart}
                    disabled={count <= 0 || submitting}>
                    Add to Cart
                  </Button>
                  <OrderButton disabled={count <= 0 || submitting}
                    item={item} count={count} onClose={handleClose}
                    requestRefresh={requestRefresh}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

function OrderButton(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { disabled, item, count, onClose, requestRefresh } = props;

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 提交
  const onSubmit = async data => {
    try {
      if (count > item.quantity) {
        return enqueueSnackbar('Out of stock', { variant: 'info' });
      }
      data.ptype = 1;
      data.pid = item.uuid;
      data.price = item.discount_price;
      data.count = count;

      await put('/cart/buy', new URLSearchParams(data));
      enqueueSnackbar('Your order has been submitted', { variant: 'success' });
      handleClose();
      onClose();
      requestRefresh();
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  }

  return (
    <>
      <Button variant='contained' color='secondary' onClick={handleClickOpen}
        disabled={disabled}>
        Order Now
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
        <DialogTitle>Order Now</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack flex={1} spacing={3} mt={3}>
              <TextField label='PO#' fullWidth autoFocus
                placeholder='po#, optional'
                helperText={errors?.po?.message}
                error={errors?.po}
                {...register('po', {
                  maxLength: {
                    value: 128, message: 'Exceed max length'
                  },
                })}
              />
              <TextField label='Note' fullWidth multiline rows={3}
                placeholder='optional'
                helperText={errors?.note?.message}
                error={errors?.note}
                {...register('note', {
                  maxLength: {
                    value: 256, message: 'Exceed max length'
                  },
                })}
              />
              <Stack alignItems='flex-end'>
                <LoadingButton variant='contained' type='submit' color='secondary'
                  loading={isSubmitting}>
                  Order
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}
