import { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import NotFound from "~/comp/notfound";
import Home from './home';
import Add from './add';
import Profile from './profile';
import Modify from './modify';

export default function Accessories() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='add' element={<Add />} />
        <Route path='profile' element={<Profile />} />
        <Route path='modify' element={<Modify />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}
