import { useRecoilValue } from "recoil";
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { format_yuan } from '~/lib/format';
import ImageGallery from "~/comp/image-gallery";
import memberState from "../state";
import Order from "./order";

export default function ResultTable(props) {
  const member = useRecoilValue(memberState);

	const { result, requestRefresh } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center" padding="none">Img</TableCell>
          <TableCell align="center">Brand</TableCell>
          <TableCell align="center">Model</TableCell>
          <TableCell align="center">P/N</TableCell>
          <TableCell align="center">Description</TableCell>
          <TableCell align="center">Quantity</TableCell>
          <TableCell align="center">Price</TableCell>
          <TableCell align="center" padding="none"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {result.map((row) => (
          <TableRow key={row.uuid}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell padding="none" width={1}>
              <Stack direction='row' spacing={'4px'}>
                <ImageGallery images={[row.image, row.image2, row.image3]} />
              </Stack>
            </TableCell>
            <TableCell align="center">{row.logo}</TableCell>
            <TableCell align="center">{row.model}</TableCell>
            <TableCell align="center">{row.pn}</TableCell>
            <TableCell align="center">{row.comment}</TableCell>
            <TableCell align="center">
              {member?.uuid ?
                (row.quantity >= 40 ? '40+' : row.quantity > 0 ? row.quantity : 0) :
                'Login to view'
              }
            </TableCell>
            <TableCell align="center">
              {member?.uuid ?
                <Tooltip arrow placement='left' title={
                  <Stack direction='row' alignItems='baseline'>
                    <Typography variant="body2">My cost:&nbsp;</Typography>
                    <Typography color="yellow">
                      $ {format_yuan(row.discount_price)}
                    </Typography>
                  </Stack>
                }>
                  <Typography variant="body2">$ {format_yuan(row.price)}</Typography>
                </Tooltip>
                :
                <Typography variant="body2">Login to view</Typography>
              }
            </TableCell>
            <TableCell align="center" padding="none">
              {member?.uuid &&
                <Order item={row} disabled={row.quantity <= 0}
                  requestRefresh={requestRefresh}
                />
              }
            </TableCell>
          </TableRow>
        ))}
        {result?.length === 0 && (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center" colSpan={20}>
              <Typography color='orange' variant="body2">
                No result, please change the query
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
