import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import useTitle from "~/hook/title";
import { post } from "~/lib/rest";
import { useSetCode } from "~/state/code";
import progressState from "~/state/progress";
import EditForm from "./edit-form";

export default function Add() {
  const navigate = useNavigate();
  const setProgress = useSetRecoilState(progressState);
  const { enqueueSnackbar } = useSnackbar();

  useSetCode(222);
  useTitle("添加Lug Bolt ");

  const onSubmit = async (data) => {
    try {
      setProgress(true);
      await post("/bolts/add", new URLSearchParams({ ...data }));
      enqueueSnackbar(`Lug Bolt  ${data.pn} 添加成功`, {
        variant: "success",
      });
      navigate("..", { replace: true });
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setProgress(false);
    }
  };

  return <EditForm mode="new" onSubmit={onSubmit} />;
}
