import PrintIcon from '@mui/icons-material/Print';
import Container from "@mui/material/Container";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import SearchInput from '~/comp/search-input';
import TimeAgo from '~/comp/timeago';
import usePageData from '~/hook/pagedata';
import useTitle from "~/hook/title";
import { format_yuan } from '~/lib/format';
import { get } from '~/lib/rest';
import { useSetCode } from "~/state/code";
import StatusMenu from './status';

export default function Home() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = usePageData();
  const [keyword, setKeyword] = useState([]);
  const [status, setStatus] = useState('');
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(pageData('rowsPerPage') || 10);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);

  useTitle('订单管理');
  useSetCode(300);

  const requestRefresh = () => {
    setRefresh(true);
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const q = new URLSearchParams({ page, rows, keyword, status });
        const resp = await get('/orders/list?' + q.toString());

        setList(resp.list || []);
        setCount(resp.count || 0);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    })();
  }, [enqueueSnackbar, page, rows, keyword, status, refresh]);

  // 搜索
  const onKeywordChange = value => {
    setKeyword(value);
    setPage(0);
  }

  // 页面改变
  const onPageChange = (e, newPage) => {
    setPage(newPage);
  }

  // 每页行数改变
  const onRowsPerPageChange = e => {
    const rows = parseInt(e.target.value, 10);

    setRows(rows);
    setPage(0);
    setPageData('rowsPerPage', rows);
  }

  // 状态
  const onStatusChange = (e, v) => {
    if (v !== null) {
      setStatus(v);
      setPage(0);
    }
  }

  const onPrintClick = item => {
    navigate('print', { state: { uuid: item.uuid } });
  };

  return (
    <Container as='main' maxWidth='lg' sx={{ mb: 4 }}>
      <Toolbar sx={{ mt: 2 }} disableGutters>
        <Stack direction='row' spacing={2}>
          <SearchInput isLoading={loading} onChange={onKeywordChange}
            placeholder={count > 0 ? `在 ${count} 条记录中搜索...` : '搜索...'}
            sx={{ minWidth: 300 }}
          />
          <ToggleButtonGroup exclusive size='small' color='primary'
            value={status} onChange={onStatusChange}>
            <ToggleButton value='' sx={{ py: '4px' }}>全部</ToggleButton>
            <ToggleButton value='1' sx={{ py: '4px' }}>未决</ToggleButton>
            <ToggleButton value='2' sx={{ py: '4px' }}>完成</ToggleButton>
            <ToggleButton value='3' sx={{ py: '4px' }}>取消</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">客户</TableCell>
            <TableCell align="center">轮毂小计</TableCell>
            <TableCell align="center">HUB RING 小计</TableCell>
            <TableCell align="center">LUG NUT 小计</TableCell>
            <TableCell align="center">LUG BOLT 小计</TableCell>
            <TableCell align="center">总金额</TableCell>
            <TableCell align="center">订单号</TableCell>
            <TableCell align="center">时间</TableCell>
            <TableCell align="center" padding='checkbox'></TableCell>
            <TableCell align="center" padding='checkbox'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => (
            <TableRow key={index} hover>
              <TableCell align="center">{row.company}</TableCell>
              <TableCell align="center">{format_yuan(row.total1)}</TableCell>
              <TableCell align="center">{format_yuan(row.total2)}</TableCell>
              <TableCell align="center">{format_yuan(row.total3)}</TableCell>
              <TableCell align="center">{format_yuan(row.total4)}</TableCell>
              <TableCell align="center">{format_yuan(row.amount)}</TableCell>
              <TableCell align="center">{row.ordno}</TableCell>
              <TableCell align="center">
                <TimeAgo time={row.create_at} variant='body2' />
              </TableCell>
              <TableCell align="center" padding='checkbox'>
                <StatusMenu item={row} requestRefresh={requestRefresh} />
              </TableCell>
              <TableCell align="center" padding='checkbox'>
                <IconButton color='primary' onClick={() => onPrintClick(row)}>
                  <PrintIcon fontSize='small' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              colSpan={20}
              count={count}
              rowsPerPage={rows}
              page={page}
              SelectProps={{ inputProps: { 'aria-label': '每页行数' } }}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Container>
  )
}
