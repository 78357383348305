import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import useTitle from "~/hook/title";
import { get, put } from "~/lib/rest";
import progressState from "~/state/progress";
import EditForm from "./edit-form";

export default function Modify() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const setProgress = useSetRecoilState(progressState);
  const [entity, setEntity] = useState(null);

  useTitle("修改Lug Bolt 资料");

  useEffect(() => {
    (async () => {
      try {
        if (location.state) {
          setProgress(true);

          const params = new URLSearchParams({ uuid: location.state.uuid });
          const resp = await get("/bolts/profile?" + params.toString());
          setEntity(resp.profile);
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
      }
    })();
  }, [location.state, enqueueSnackbar, setProgress]);

  const onSubmit = async (data) => {
    try {
      setProgress(true);

      await put(
        "/bolts/modify",
        new URLSearchParams({ uuid: location.state.uuid, ...data })
      );
      enqueueSnackbar(`Lug Bolt  ${data.pn} 修改成功`, {
        variant: "success",
      });
      navigate("..", { replace: true });
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setProgress(false);
    }
  };

  return <EditForm onSubmit={onSubmit} entity={entity} />;
}
