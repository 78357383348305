import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { Fragment, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Banner from "~/comp/banner";
import OutlinedPaper from "~/comp/outlined-paper";
import usePrint from "~/hook/print";
import useTitle from "~/hook/title";
import { format_yuan } from "~/lib/format";
import { get } from "~/lib/rest";
import { useSetCode } from "~/state/code";
import progressState from "~/state/progress";

export default function Print() {
  const location = useLocation();
  const navigate = useNavigate();
  const setProgress = useSetRecoilState(progressState);
  const { enqueueSnackbar } = useSnackbar();
  const [order, setOrder] = useState({});
  const [wheelCarts, setWheelCarts] = useState([]);
  const [wheelTotal, setWheelTotal] = useState(0);
  const [wheelTax, setWheelTax] = useState(0);
  const [wheelAmount, setWheelAmount] = useState(0);
  const [accessoryCarts, setAccessoryCarts] = useState([]);
  const [accessoryTotal, setAccessoryTotal] = useState(0);
  const [accessoryTax, setAccessoryTax] = useState(0);
  const [accessoryAmount, setAccessoryAmount] = useState(0);
  const [nutCarts, setNutCarts] = useState([]);
  const [nutTotal, setNutTotal] = useState(0);
  const [nutTax, setNutTax] = useState(0);
  const [nutAmount, setNutAmount] = useState(0);
  const [boltCarts, setBoltCarts] = useState([]);
  const [boltTotal, setBoltTotal] = useState(0);
  const [boltTax, setBoltTax] = useState(0);
  const [boltAmount, setBoltAmount] = useState(0);

  useHotkeys(
    "esc",
    () => {
      navigate("..");
    },
    { enableOnTags: ["INPUT"] }
  );
  useTitle("打印订单");
  useSetCode(300);

  const contentRef = useRef();
  const print = usePrint(contentRef.current);

  useEffect(() => {
    (async () => {
      try {
        if (location.state) {
          setProgress(true);

          const params = new URLSearchParams({ uuid: location.state.uuid });
          const resp = await get("/orders/print?" + params.toString());

          setOrder(resp?.order || {});

          setWheelCarts(resp?.wheel_carts || []);
          setWheelTotal(resp?.wheel_total || 0);
          setWheelTax(resp?.wheel_tax || 0);
          setWheelAmount(resp?.wheel_amount || 0);

          setAccessoryCarts(resp?.accessory_carts || []);
          setAccessoryTotal(resp?.accessory_total || 0);
          setAccessoryTax(resp?.accessory_tax || 0);
          setAccessoryAmount(resp?.accessory_amount || 0);

          setNutCarts(resp?.nut_carts || []);
          setNutTotal(resp?.nut_total || 0);
          setNutTax(resp?.nut_tax || 0);
          setNutAmount(resp?.nut_amount || 0);

          setBoltCarts(resp?.bolt_carts || []);
          setBoltTotal(resp?.bolt_total || 0);
          setBoltTax(resp?.bolt_tax || 0);
          setBoltAmount(resp?.bolt_amount || 0);
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
      }
    })();
  }, [location.state, enqueueSnackbar, setProgress]);

  if (!location.state?.uuid) {
    return <Navigate to=".." />;
  }

  return (
    <Container as="main" maxWidth="full">
      <Stack direction="row" justifyContent="flex-end" spacing={2} my={2}>
        <Button
          LinkComponent={Link}
          to=".."
          variant="outlined"
          size="small"
          startIcon={<ArrowBackIcon />}
        >
          返回
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="success"
          onClick={print}
          startIcon={<PrintIcon />}
        >
          打印
        </Button>
      </Stack>
      <Stack ref={contentRef} mb={4}>
        <Stack alignItems="flex-start" mb={4}>
          <Banner height={32} />
        </Stack>
        <Stack sx={{ mb: 3, borderLeft: "6px solid", pl: 2 }}>
          <Typography variant="h6">Order Info</Typography>
        </Stack>
        <OrderInfo order={order} />
        {wheelCarts?.length > 0 && (
          <>
            <Stack sx={{ my: 3, borderLeft: "6px solid", pl: 2 }}>
              <Typography variant="h6">Wheels Info</Typography>
            </Stack>
            <WheelsTable wheelCarts={wheelCarts} />
            <Stack alignItems="flex-end" m={1}>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2">Sub Total:</Typography>
                <Typography color="red">
                  $ {format_yuan(wheelTotal)} CAD
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2">Tax:</Typography>
                <Typography color="red">
                  $ {format_yuan(wheelTax)} CAD
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="button">Total Amount:</Typography>
                <Typography fontSize="large" fontWeight="bold" color="red">
                  $ {format_yuan(wheelAmount)} CAD
                </Typography>
              </Stack>
            </Stack>
          </>
        )}

        {accessoryCarts?.length > 0 && (
          <>
            <Stack sx={{ my: 3, borderLeft: "6px solid", pl: 2 }}>
              <Typography variant="h6">Hub Ring Info</Typography>
            </Stack>
            <AccessoriesTable accessoryCarts={accessoryCarts} />
            <Stack alignItems="flex-end" m={1}>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2">Sub Total:</Typography>
                <Typography color="red">
                  $ {format_yuan(accessoryTotal)} CAD
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2">Tax:</Typography>
                <Typography color="red">
                  $ {format_yuan(accessoryTax)} CAD
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="button">Total Amount:</Typography>
                <Typography fontSize="large" fontWeight="bold" color="red">
                  $ {format_yuan(accessoryAmount)} CAD
                </Typography>
              </Stack>
            </Stack>
          </>
        )}

        {nutCarts?.length > 0 && (
          <>
            <Stack sx={{ my: 3, borderLeft: "6px solid", pl: 2 }}>
              <Typography variant="h6">Lug Nut Info</Typography>
            </Stack>
            <NutsTable nutCarts={nutCarts} />
            <Stack alignItems="flex-end" m={1}>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2">Sub Total:</Typography>
                <Typography color="red">
                  $ {format_yuan(nutTotal)} CAD
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2">Tax:</Typography>
                <Typography color="red">$ {format_yuan(nutTax)} CAD</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="button">Total Amount:</Typography>
                <Typography fontSize="large" fontWeight="bold" color="red">
                  $ {format_yuan(nutAmount)} CAD
                </Typography>
              </Stack>
            </Stack>
          </>
        )}

        {boltCarts?.length > 0 && (
          <>
            <Stack sx={{ my: 3, borderLeft: "6px solid", pl: 2 }}>
              <Typography variant="h6">Lug Bolt Info</Typography>
            </Stack>
            <BoltsTable boltCarts={boltCarts} />
            <Stack alignItems="flex-end" m={1}>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2">Sub Total:</Typography>
                <Typography color="red">
                  $ {format_yuan(boltTotal)} CAD
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="body2">Tax:</Typography>
                <Typography color="red">$ {format_yuan(boltTax)} CAD</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="baseline">
                <Typography variant="button">Total Amount:</Typography>
                <Typography fontSize="large" fontWeight="bold" color="red">
                  $ {format_yuan(boltAmount)} CAD
                </Typography>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Container>
  );
}

function OrderInfo(props) {
  const { order } = props;

  return (
    <Stack direction="row" ml={3}>
      <Stack flex={1}>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Order No:</Typography>
          <Typography>{order.ordno}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Note:</Typography>
          <Typography>{order.note}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Order Status:</Typography>
          {order.status === 1 && <Typography>Pending</Typography>}
          {order.status === 2 && <Typography>Done</Typography>}
          {order.status === 3 && <Typography>About</Typography>}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Total Amount:</Typography>
          <Typography color="red" fontWeight="bold">
            $ {format_yuan(order.amount)} CAD
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">PO#:</Typography>
          <Typography>{order.po}</Typography>
        </Stack>
      </Stack>
      <Stack flex={1}>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Dealer Name:</Typography>
          <Typography>{order.company}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Shipping Address:</Typography>
          <Typography>
            {order.address1} {order.city} {order.province}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Shipping Tel:</Typography>
          <Typography>{order.tel}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Billing Address:</Typography>
          <Typography>
            {order.address2 ||
              order.address1 + " " + order.city + " " + order.province}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Typography variant="subtitle2">Billing Tel:</Typography>
          <Typography>{order.tel2 || order.tel}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

function WheelsTable(props) {
  const { wheelCarts } = props;

  return (
    <TableContainer component={OutlinedPaper}>
      <Table
        sx={{
          "th:not(:last-child)": {
            borderRight: "1px solid #8884",
          },
          "td:not(:last-child)": {
            borderRight: "1px solid #8884",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" rowSpan={2}>
              Img
            </TableCell>
            <TableCell align="center">Brand</TableCell>
            <TableCell align="center">P/N</TableCell>
            <TableCell align="center">Size</TableCell>
            <TableCell align="center">H/PCD</TableCell>
            <TableCell align="center">Offset</TableCell>
            <TableCell align="center">Bore</TableCell>
            <TableCell align="center">Seat</TableCell>
            <TableCell align="center">Finish</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Count</TableCell>
            <TableCell align="center">Subtotal</TableCell>
            <TableCell align="center">Tax</TableCell>
            <TableCell align="center">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ "tr:last-child td": { borderBottom: 0 } }}>
          {wheelCarts?.map((item) => (
            <Fragment key={item.uuid}>
              <TableRow>
                <TableCell
                  align="center"
                  padding="none"
                  rowSpan={item.attention ? 2 : 1}
                >
                  {item.image && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        alt=""
                        src={`/image/?u=${item.image}`}
                        style={{
                          objectFit: "contain",
                          width: 42,
                        }}
                      />
                    </Stack>
                  )}
                </TableCell>
                <TableCell align="center">{item.logo}</TableCell>
                <TableCell align="center">{item.pn}</TableCell>
                <TableCell align="center">
                  {item.diameter}*{item.width}
                </TableCell>
                <TableCell align="center">
                  {item.hole}/{item.pcd}
                </TableCell>
                <TableCell align="center">{item.et}</TableCell>
                <TableCell align="center">{item.cb}</TableCell>
                <TableCell align="center">{item.seat}</TableCell>
                <TableCell align="center">{item.color}</TableCell>
                <TableCell align="center">{format_yuan(item.price)}</TableCell>
                <TableCell align="center">{item.count}</TableCell>
                <TableCell align="center">{format_yuan(item.total)}</TableCell>
                <TableCell align="center">{format_yuan(item.tax)}</TableCell>
                <TableCell align="center">{format_yuan(item.amount)}</TableCell>
              </TableRow>
              {item.attention && (
                <TableRow>
                  <TableCell align="left" colSpan={20} sx={{ py: 1 }}>
                    Attention: {item.attention}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AccessoriesTable(props) {
  const { accessoryCarts } = props;

  return (
    <TableContainer component={OutlinedPaper}>
      <Table
        sx={{
          "th:not(:last-child)": {
            borderRight: "1px solid #8884",
          },
          "td:not(:last-child)": {
            borderRight: "1px solid #8884",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Img</TableCell>
            <TableCell align="center">Brand</TableCell>
            <TableCell align="center">Model</TableCell>
            <TableCell align="center">P/N</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Count</TableCell>
            <TableCell align="center">Subtotal</TableCell>
            <TableCell align="center">Tax</TableCell>
            <TableCell align="center">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ "tr:last-child td": { borderBottom: 0 } }}>
          {accessoryCarts?.map((item) => (
            <Fragment key={item.uuid}>
              <TableRow>
                <TableCell
                  align="center"
                  padding="none"
                  rowSpan={item.attention ? 2 : 1}
                >
                  {item.image && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        alt=""
                        src={`/image/?u=${item.image}`}
                        style={{
                          objectFit: "contain",
                          width: 42,
                        }}
                      />
                    </Stack>
                  )}
                </TableCell>
                <TableCell align="center">{item.logo}</TableCell>
                <TableCell align="center">{item.model}</TableCell>
                <TableCell align="center">{item.pn}</TableCell>
                <TableCell align="center">{format_yuan(item.price)}</TableCell>
                <TableCell align="center">{item.count}</TableCell>
                <TableCell align="center">{format_yuan(item.total)}</TableCell>
                <TableCell align="center">{format_yuan(item.tax)}</TableCell>
                <TableCell align="center">{format_yuan(item.amount)}</TableCell>
              </TableRow>
              {item.attention && (
                <TableRow>
                  <TableCell align="left" colSpan={20} sx={{ py: 1 }}>
                    Attention: {item.attention}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function NutsTable(props) {
  const { nutCarts } = props;

  return (
    <TableContainer component={OutlinedPaper}>
      <Table
        sx={{
          "th:not(:last-child)": {
            borderRight: "1px solid #8884",
          },
          "td:not(:last-child)": {
            borderRight: "1px solid #8884",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Img</TableCell>
            <TableCell align="center">Brand</TableCell>
            <TableCell align="center">P/N</TableCell>
            <TableCell align="center">Hex</TableCell>
            <TableCell align="center">Seat</TableCell>
            <TableCell align="center">Thread</TableCell>
            <TableCell align="center">ShankLength</TableCell>
            <TableCell align="center">Finish</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Count</TableCell>
            <TableCell align="center">Subtotal</TableCell>
            <TableCell align="center">Tax</TableCell>
            <TableCell align="center">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ "tr:last-child td": { borderBottom: 0 } }}>
          {nutCarts?.map((item) => (
            <Fragment key={item.uuid}>
              <TableRow>
                <TableCell
                  align="center"
                  padding="none"
                  rowSpan={item.attention ? 2 : 1}
                >
                  {item.image && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        alt=""
                        src={`/image/?u=${item.image}`}
                        style={{
                          objectFit: "contain",
                          width: 42,
                        }}
                      />
                    </Stack>
                  )}
                </TableCell>
                <TableCell align="center">{item.brand}</TableCell>
                <TableCell align="center">{item.pn}</TableCell>
                <TableCell align="center">{item.hex}</TableCell>
                <TableCell align="center">{item.seat}</TableCell>
                <TableCell align="center">{item.thread}</TableCell>
                <TableCell align="center">{item.shanklength}</TableCell>
                <TableCell align="center">{item.finish}</TableCell>
                <TableCell align="center">{format_yuan(item.price)}</TableCell>
                <TableCell align="center">{item.count}</TableCell>
                <TableCell align="center">{format_yuan(item.total)}</TableCell>
                <TableCell align="center">{format_yuan(item.tax)}</TableCell>
                <TableCell align="center">{format_yuan(item.amount)}</TableCell>
              </TableRow>
              {item.attention && (
                <TableRow>
                  <TableCell align="left" colSpan={20} sx={{ py: 1 }}>
                    Attention: {item.attention}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


function BoltsTable(props) {
  const { boltCarts } = props;

  return (
    <TableContainer component={OutlinedPaper}>
      <Table
        sx={{
          "th:not(:last-child)": {
            borderRight: "1px solid #8884",
          },
          "td:not(:last-child)": {
            borderRight: "1px solid #8884",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell align="center">Img</TableCell>
            <TableCell align="center">Brand</TableCell>
            <TableCell align="center">P/N</TableCell>
            <TableCell align="center">Hex</TableCell>
            <TableCell align="center">Seat</TableCell>
            <TableCell align="center">Thread</TableCell>
            <TableCell align="center">ShankLength</TableCell>
            <TableCell align="center">Finish</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Count</TableCell>
            <TableCell align="center">Subtotal</TableCell>
            <TableCell align="center">Tax</TableCell>
            <TableCell align="center">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ "tr:last-child td": { borderBottom: 0 } }}>
          {boltCarts?.map((item) => (
            <Fragment key={item.uuid}>
              <TableRow>
                <TableCell
                  align="center"
                  padding="none"
                  rowSpan={item.attention ? 2 : 1}
                >
                  {item.image && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img
                        alt=""
                        src={`/image/?u=${item.image}`}
                        style={{
                          objectFit: "contain",
                          width: 42,
                        }}
                      />
                    </Stack>
                  )}
                </TableCell>
                <TableCell align="center">{item.brand}</TableCell>
                <TableCell align="center">{item.pn}</TableCell>
                <TableCell align="center">{item.hex}</TableCell>
                <TableCell align="center">{item.seat}</TableCell>
                <TableCell align="center">{item.thread}</TableCell>
                <TableCell align="center">{item.shanklength}</TableCell>
                <TableCell align="center">{item.finish}</TableCell>
                <TableCell align="center">{format_yuan(item.price)}</TableCell>
                <TableCell align="center">{item.count}</TableCell>
                <TableCell align="center">{format_yuan(item.total)}</TableCell>
                <TableCell align="center">{format_yuan(item.tax)}</TableCell>
                <TableCell align="center">{format_yuan(item.amount)}</TableCell>
              </TableRow>
              {item.attention && (
                <TableRow>
                  <TableCell align="left" colSpan={20} sx={{ py: 1 }}>
                    Attention: {item.attention}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
