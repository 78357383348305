import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useSetRecoilState } from "recoil";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import OutlinedPaper from '~/comp/outlined-paper';
import progressState from '~/state/progress';
import useTitle from "~/hook/title";
import usePrint from '~/hook/print';
import { get } from '~/lib/rest';

export default function Profile() {
  const location = useLocation();
  const navigate = useNavigate();
  const setProgress = useSetRecoilState(progressState);
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState({});

  useHotkeys('esc', () => { navigate('..'); }, { enableOnTags: ["INPUT"] });
  useTitle('客户详细资料');

  const contentRef = useRef();
  const print = usePrint(contentRef.current);

  useEffect(() => {
    (async () => {
      try {
        if (location.state) {
          setProgress(true);

          const params = new URLSearchParams({ uuid: location.state.uuid });
          const resp = await get('/member/profile?' + params.toString());
          setProfile(resp?.profile || {});
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
      }
    })();
  }, [location.state, enqueueSnackbar, setProgress]);

  if (!location.state?.uuid) {
    return <Navigate to='..' />
  }

  return (
    <Container as='main' maxWidth='md'>
      <Paper ref={contentRef} sx={{
        px: 4, py: 3, my: 5, '@media print': {
          boxShadow: 0, borderWidth: 0,
        }
      }}>
        <Stack direction='row' alignItems='center' sx={{ mb: 3 }}>
          <IconButton aria-label='返回' onClick={() => { navigate('..') }}
            sx={{ mr: 1 }}>
            <ArrowBackIcon color='primary' />
          </IconButton>
          <Typography variant='h5' gutterBottom={false} sx={{ flex: 1 }}>
            客户详细资料
          </Typography>
          <Tooltip title='打印'>
            <IconButton aria-label='打印' onClick={print}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <ProfileTable profile={profile} ischeck={false} />
      </Paper>
    </Container>
  )
}

function ProfileTable(props) {
  const { profile, ischeck } = props;

  return (
    <TableContainer component={OutlinedPaper}>
      <Table>
        <TableBody sx={{
          'td:not(:last-child)': {
            borderRight: '1px solid #8884',
          },
          'td:nth-of-type(2n+1)': {
            width: '1%', whiteSpace: 'nowrap',
          },
          'tr:last-child td': {
            borderBottom: 0,
          }
        }}>
          <TableRow>
            <TableCell>姓名</TableCell>
            <TableCell>{profile.firstname} {profile.lastname}</TableCell>
            <TableCell>邮箱</TableCell>
            <TableCell>{profile.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>公司</TableCell>
            <TableCell colSpan={3}>{profile.company}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>电话</TableCell>
            <TableCell>{profile.tel}</TableCell>
            <TableCell>传真</TableCell>
            <TableCell>{profile.fax}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cell</TableCell>
            <TableCell colSpan={3}>{profile.cell}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>街道地址</TableCell>
            <TableCell colSpan={3}>{profile.address1}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>国家</TableCell>
            <TableCell>{profile.country}</TableCell>
            <TableCell>省</TableCell>
            <TableCell>{profile.province}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>市</TableCell>
            <TableCell>{profile.city}</TableCell>
            <TableCell>邮编</TableCell>
            <TableCell>{profile.postal}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Business# / Tax ID#</TableCell>
            <TableCell>{profile.tax}</TableCell>
            <TableCell>税率</TableCell>
            <TableCell>{profile.tax_rate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>账单地址</TableCell>
            <TableCell colSpan={3}>{profile.address2}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>账单电话</TableCell>
            <TableCell colSpan={3}>{profile.tel2}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>创建时间</TableCell>
            <TableCell>
              {dayjs(profile.create_at).format('LL LTS')}
            </TableCell>
            <TableCell>已审核</TableCell>
            <TableCell>
              {profile.checked ? '是' : '否'}
            </TableCell>
          </TableRow>
          {!ischeck &&
            <TableRow>
              <TableCell>折扣规则</TableCell>
              <TableCell colSpan={3}>
                {profile.level_name && (
                  <Typography variant='body2'>
                    {profile.level_name}
                    （轮毂折扣 {profile.level_discount1}，配件折扣 {profile.level_discount2}）
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { ProfileTable }
