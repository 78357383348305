import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { Link } from 'react-router-dom';
import Container from "@mui/material/Container";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableContainer from "@mui/material/TableContainer";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import useTitle from "~/hook/title";
import { useSetCode } from "~/state/code";
import progressState from "~/state/progress";
import { get, del } from "~/lib/rest";
import Add from "./add";
import Modify from "./modify";

export default function Level() {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const setProgress = useSetRecoilState(progressState);
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(true);

  useTitle('折扣规则');
  useSetCode(100);

  const requestRefresh = () => {
    setRefresh(true);
  }

  useEffect(() => {
    (async () => {
      try {
        if (refresh) {
          setProgress(true);

          const resp = await get('/member/level/list');
          setList(resp.list || []);
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
        setRefresh(false);
      }
    })();
  }, [enqueueSnackbar, setProgress, refresh]);

  // 删除
  const onDeleteClick = async item => {
    try {
      await confirm({
        description: `确定要删除吗？`,
        confirmationText: '删除',
        confirmationButtonProps: { color: 'error' },
      });
      const params = new URLSearchParams({ uuid: item.uuid });
      await del('/member/level/del?' + params.toString());
      enqueueSnackbar('已删除', { variant: 'success' });
      setRefresh(true);
    } catch (err) {
      if (err) {
        enqueueSnackbar(err.message);
      }
    }
  }

  return (
    <Container maxWidth='md' sx={{ my: 5 }}>
      <Stack direction='row' justifyContent='flex-start' spacing={2} mb={3}>
        <Button size="small" startIcon={<ArrowBackIcon />} color='warning'
          LinkComponent={Link} to='..'>
          返回
        </Button>
        <Box flex={1} />
        <Add requestRefresh={requestRefresh} />
      </Stack>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">规则名称</TableCell>
              <TableCell align="center">WIC 轮毂折扣</TableCell>
              <TableCell align="center">FTR 轮毂折扣</TableCell>
              <TableCell align="center">配件折扣</TableCell>
              <TableCell align="center" padding="none"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row, index) => (
              <TableRow key={index} hover>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.discount1}</TableCell>
                <TableCell align="center">{row.discount2}</TableCell>
                <TableCell align="center">{row.discount3}</TableCell>
                <TableCell align="center" padding="none">
                  <Modify item={row} requestRefresh={requestRefresh} />
                  <IconButton onClick={() => onDeleteClick(row)}>
                    <CloseIcon fontSize="small" color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}
