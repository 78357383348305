import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import {
  Navigate, useLocation, useNavigate, Link as RouteLink
} from "react-router-dom";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'notistack';
import progressState from "~/state/progress";
import useTitle from "~/hook/title";
import { get, put } from "~/lib/rest";

export default function Modify() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const setProgress = useSetRecoilState(progressState);

  useHotkeys('esc', () => { navigate('..'); }, { enableOnTags: ["INPUT"] });
  useTitle('修改轮毂资料');

  const { register, handleSubmit, setValue, formState: {
    errors, isSubmitting
  }} = useForm();

  useEffect(() => {
    (async () => {
      try {
        if (location.state) {
          setProgress(true);

          const params = new URLSearchParams({ uuid: location.state.uuid });
          const resp = await get('/wheels/profile?' + params.toString());
          setValue("model", resp?.profile?.model);
          setValue("pn", resp?.profile?.pn);
          setValue("diameter", resp?.profile?.diameter);
          setValue("width", resp?.profile?.width);
          setValue("hole", resp?.profile?.hole);
          setValue("pcd", resp?.profile?.pcd);
          setValue("et", resp?.profile?.et);
          setValue("cb", resp?.profile?.cb);
          setValue("color", resp?.profile?.color);
          setValue("logo", resp?.profile?.logo);
          setValue("eta", resp?.profile?.eta);
          setValue("seat", resp?.profile?.seat);
          setValue("quantity", resp?.profile?.quantity);
          setValue("attention", resp?.profile?.attention);
          setValue("price", resp?.profile?.price);
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
      }
    })();
  }, [location.state, enqueueSnackbar, setProgress, setValue]);

  const onSubmit = async data => {
    try {
      setProgress(true);

      data.uuid = location.state.uuid;
      data._audit = `修改轮毂 ${data.model} 的资料`;

      await put('/wheels/modify', new URLSearchParams(data));
      enqueueSnackbar('更新成功', { variant: 'success' });
      navigate('..', { replace: true });
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setProgress(false);
    }
  }

  if (!location.state?.uuid) {
    return <Navigate to='..' />
  }

  return (
    <Container as='main' maxWidth='md' sx={{ mb: 6 }}>
      <Paper sx={{ px: 4, py: 3, mt: 5 }}>
        <Stack direction='row' alignItems='center' spacing={1} sx={{ mb: 3 }}>
          <IconButton aria-label="返回" component={RouteLink} to='..'>
            <ArrowBackIcon color='primary' />
          </IconButton>
          <Typography variant='h5'>修改轮毂资料</Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper variant='outlined' sx={{ px: 4, py: 3 }}>
            <Stack spacing={4}>
              <Stack direction='row' spacing={3}>
                <TextField label='型号(Model)' variant='standard' fullWidth required
                  placeholder='Model'
                  helperText={errors?.model?.message}
                  error={errors?.model}
                  InputLabelProps={{ shrink: true }}
                  {...register('model', {
                    required: "Required",
                    maxLength: {
                      value: 64, message: 'Exceed max length'
                    },
                  })}
                />
                <TextField label='零件编号(P/N)' variant='standard' fullWidth
                  required placeholder='Part Number'
                  helperText={errors?.pn?.message}
                  error={errors?.pn}
                  InputLabelProps={{ shrink: true }}
                  {...register('pn', {
                    required: "Required",
                    maxLength: {
                      value: 64, message: 'Exceed max length'
                    },
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='直径' variant='standard' fullWidth required
                  placeholder='Diameter' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.diameter?.message}
                  error={errors?.diameter ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('diameter', {
                    required: "不能为空",
                  })}
                />
                <TextField label='宽度' variant='standard' fullWidth required
                  placeholder='Width' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.width?.message}
                  error={errors?.width ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('width', {
                    required: "不能为空",
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='螺柱孔数(Holes)' variant='standard' fullWidth required
                  placeholder='Number of stud holes' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.hole?.message}
                  error={errors?.hole ? true : false}
                  inputProps={{ min: 0 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('hole', {
                    required: "不能为空",
                  })}
                />
                <TextField label='节圆直径(PCD)' variant='standard' fullWidth required
                  placeholder='Pitch circle diameter' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.pcd?.message}
                  error={errors?.pcd ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('pcd', {
                    required: "不能为空",
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='偏移(ET)' variant='standard' fullWidth required
                  placeholder='Offset' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.et?.message}
                  error={errors?.et ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('et', {
                    required: "不能为空",
                  })}
                />
                <TextField label='中心孔直径(CB)' variant='standard' fullWidth required
                  placeholder='Centre bore diameter' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.cb?.message}
                  error={errors?.cb ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('cb', {
                    required: "不能为空",
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='颜色(Color)' variant='standard' fullWidth
                  required placeholder='Color'
                  helperText={errors?.color?.message}
                  error={errors?.color}
                  InputLabelProps={{ shrink: true }}
                  {...register('color', {
                    required: "Required",
                    maxLength: {
                      value: 64, message: 'Exceed max length'
                    },
                  })}
                />
                <TextField label='品牌(Brand/Logo)' variant='standard' fullWidth
                  required placeholder='Logo'
                  disabled={isSubmitting}
                  helperText={errors?.logo?.message}
                  error={errors?.logo ? true : false}
                  InputLabelProps={{ shrink: true }}
                  {...register('logo', {
                    required: "不能为空",
                    maxLength: {
                      value: 128, message: '超出最大长度'
                    },
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='到货时间(ETA)' variant='standard' fullWidth
                  required placeholder='ETA'
                  disabled={isSubmitting}
                  helperText={errors?.eta?.message}
                  error={errors?.eta ? true : false}
                  InputLabelProps={{ shrink: true }}
                  {...register('eta', {
                    required: "不能为空",
                    maxLength: {
                      value: 128, message: '超出最大长度'
                    },
                  })}
                />
                <TextField label='Seat' variant='standard' fullWidth
                  required placeholder='Seat'
                  disabled={isSubmitting}
                  helperText={errors?.seat?.message}
                  error={errors?.seat ? true : false}
                  InputLabelProps={{ shrink: true }}
                  {...register('seat', {
                    required: "不能为空",
                    maxLength: {
                      value: 128, message: '超出最大长度'
                    },
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='库存(Quantity)' variant='standard' fullWidth
                  required placeholder='Quantity' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.quantity?.message}
                  error={errors?.quantity ? true : false}
                  inputProps={{ min: 0 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('quantity', {
                    required: "不能为空",
                  })}
                />
                <TextField label='价格(Price)' variant='standard' fullWidth
                  required placeholder='Price' type='number'
                  helperText={errors?.price?.message}
                  error={errors?.price}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('price', {
                    required: "Required",
                  })}
                />
              </Stack>
              <TextField label='Attention' variant='standard' fullWidth
                placeholder='Attention'
                disabled={isSubmitting}
                helperText={errors?.attention?.message}
                error={errors?.attention ? true : false}
                InputLabelProps={{ shrink: true }}
                {...register('attention', {
                  maxLength: {
                    value: 256, message: '超出最大长度'
                  },
                })}
              />
            </Stack>
          </Paper>
          <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ mt: 4 }}>
            <Button color='secondary' disabled={isSubmitting}
              onClick={() => { navigate('..') }}>
              取消
            </Button>
            <LoadingButton variant='contained' type='submit'
              loading={isSubmitting}>
              提交
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}
