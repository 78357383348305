import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";
import Banner from './banner';

export default function Footer(props) {
  const theme = useTheme();
  const sm_up = useMediaQuery(theme.breakpoints.up('sm'));

  const { nobanner, ...others } = props;

  return (
    <Stack {...others} spacing={1}>
      <Stack direction={sm_up ? 'row' : 'column-reverse'}
        alignItems='center' justifyContent='center' spacing={1}>
        <Typography variant='caption'>
          Copyright &copy; {new Date().getFullYear()} {process.env.REACT_APP_COMPANY_NAME_FULL}
        </Typography>
      </Stack>
      {!nobanner && <Banner height={26} />}
    </Stack>
  )
}
