import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DiscountIcon from '@mui/icons-material/Discount';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { useSecretCode } from '~/comp/secretcode';
import { del } from '~/lib/rest';
import LevelDialog from './nlevel';

export default function MoreMenu(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const secretCode = useSecretCode();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const { item, requestRefresh } = props;

  const onOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  // 用户资料
  const onProfileClick = () => {
    navigate('profile', { state: { uuid: item.uuid } });
  };

  // 修改资料
  const onModifyClick = () => {
    navigate('modify', { state: { uuid: item.uuid } });
  };

  const onLevelClick = () => {
    navigate('nlevel', { state: { uuid: item.uuid, nlevel: item.nlevel } });
  };

  // 删除
  const onDeleteClick = async () => {
    try {
      await confirm({
        description: `确定要删除客户 ${item.name} 吗？`,
        confirmationText: '删除',
        confirmationButtonProps: { color: 'error' },
      });
      const token = await secretCode();

      const _audit = `删除客户 ${item.name}`;

      const params = new URLSearchParams({
        uuid: item.uuid, secretcode_token: token, _audit,
      });
      await del('/member/del?' + params.toString());
      enqueueSnackbar('客户已删除', { variant: 'success' });
      requestRefresh();
    } catch (err) {
      if (err) {
        enqueueSnackbar(err.message);
      }
    }
  }

  return (
    <>
      <IconButton color='primary' aria-label='菜单' onClick={onOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <MenuItem onClick={onProfileClick}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>详细资料</ListItemText>
        </MenuItem>
        <MenuItem disabled={item.deleted} onClick={onModifyClick}>
          <ListItemIcon>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>修改资料</ListItemText>
        </MenuItem>
        <MenuItem disabled={item.deleted} onClick={onLevelClick}>
          <ListItemIcon>
            <DiscountIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>修改折扣</ListItemText>
        </MenuItem>
        <LevelDialog item={item} requestRefresh={requestRefresh} />
        <Divider />
        <MenuItem disabled={item.deleted} onClick={onDeleteClick}>
          <ListItemIcon>
            <RemoveCircleOutlineIcon fontSize="small" color='error' />
          </ListItemIcon>
          <ListItemText>删除</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}
