import { useEffect, useState } from "react";
import {
  Navigate, useLocation, useNavigate, Link as RouteLink
} from "react-router-dom";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'notistack';
import useTitle from "~/hook/title";
import { get, post } from "~/lib/rest";

export default function NLevel() {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [levels, setLevels] = useState([]);
  const [level, setLevel] = useState('');

  useHotkeys('esc', () => { navigate('..'); }, { enableOnTags: ["INPUT"] });
  useTitle('修改折扣规则');

  useEffect(() => {
    (async () => {
      try {
        const resp2 = await get('/member/level/list');
        setLevels(resp2.list || []);
        setLevel(location.state?.nlevel || '');
      } catch (err) {
        enqueueSnackbar(err.message);
      }
    })();
  }, [enqueueSnackbar, location.state]);

  const onLevelChange = e => {
    setLevel(e.target.value);
  }

  const onSubmit = async () => {
    try {
      if (!level) {
        return enqueueSnackbar('请选择折扣规则', { variant: 'warning' });
      }
      await post('/member/nlevel', new URLSearchParams({
        uuid: location.state?.uuid, level
      }));
      enqueueSnackbar("修改成功", { variant: 'success' });
      navigate('..');
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  }

  if (!location.state?.uuid) {
    return <Navigate to='..' />
  }

  return (
    <Container as='main' maxWidth='sm' sx={{ mb: 6 }}>
      <Paper sx={{ px: 4, py: 3, mt: 5 }}>
        <Stack direction='row' alignItems='center' spacing={1} sx={{ mb: 3 }}>
          <IconButton aria-label="返回" component={RouteLink} to='..'>
            <ArrowBackIcon color='primary' />
          </IconButton>
          <Typography variant='h5'>修改折扣规则</Typography>
        </Stack>
        <TextField required fullWidth select label="客户折扣规则"
          value={level} onChange={onLevelChange}>
          {levels.map((option) => (
            <MenuItem key={option.uuid} value={option.uuid}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <Stack mt={4} alignItems='flex-end'>
          <Button variant='contained' onClick={onSubmit}>修改</Button>
        </Stack>
      </Paper>
    </Container>
  )
}
