import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import { useSetRecoilState } from "recoil";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import OutlinedPaper from '~/comp/outlined-paper';
import progressState from '~/state/progress';
import useTitle from "~/hook/title";
import usePrint from '~/hook/print';
import { get } from '~/lib/rest';
import { format_yuan } from '~/lib/format';

export default function Profile() {
  const location = useLocation();
  const navigate = useNavigate();
  const setProgress = useSetRecoilState(progressState);
  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState({});

  useHotkeys('esc', () => { navigate('..'); }, { enableOnTags: ["INPUT"] });
  useTitle('轮毂详细资料');

  const contentRef = useRef();
  const print = usePrint(contentRef.current);

  useEffect(() => {
    (async () => {
      try {
        if (location.state) {
          setProgress(true);

          const params = new URLSearchParams({ uuid: location.state.uuid });
          const resp = await get('/wheels/profile?' + params.toString());
          setProfile(resp?.profile || {});
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
      }
    })();
  }, [location.state, enqueueSnackbar, setProgress]);

  if (!location.state?.uuid) {
    return <Navigate to='..' />
  }

  return (
    <Container as='main' maxWidth='md'>
      <Paper ref={contentRef} sx={{
        px: 4, py: 3, my: 5, '@media print': {
          boxShadow: 0, borderWidth: 0,
        }
      }}>
        <Stack direction='row' alignItems='center' sx={{ mb: 3 }}>
          <IconButton aria-label='返回' onClick={() => { navigate('..') }}
            sx={{ mr: 1 }}>
            <ArrowBackIcon color='primary' />
          </IconButton>
          <Typography variant='h5' gutterBottom={false} sx={{ flex: 1 }}>
            轮毂详细资料
          </Typography>
          <Tooltip title='打印'>
            <IconButton aria-label='打印' onClick={print}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <ProfileTable profile={profile} />
      </Paper>
    </Container>
  )
}

function ProfileTable(props) {
  const { profile } = props;

  return (
    <TableContainer component={OutlinedPaper}>
      <Table>
        <TableBody sx={{
          'td:not(:last-child)': {
            borderRight: '1px solid #8884',
          },
          'td:nth-of-type(2n+1)': {
            width: '1%', whiteSpace: 'nowrap',
          },
          'tr:last-child td': {
            borderBottom: 0,
          }
        }}>
          <TableRow>
            <TableCell>型号</TableCell>
            <TableCell>{profile.model}</TableCell>
            <TableCell colSpan={2}>
              <Stack direction='row' spacing={1}>
                {profile.image &&
                  <img src={`/image/?u=${profile.image}`} alt='' style={{
                    objectFit: 'contain', height: 48
                  }} />
                }
                {profile.image2 &&
                  <img src={`/image/?u=${profile.image2}`} alt='' style={{
                    objectFit: 'contain', height: 48
                  }} />
                }
                {profile.image3 &&
                  <img src={`/image/?u=${profile.image3}`} alt='' style={{
                    objectFit: 'contain', height: 48
                  }} />
                }
              </Stack>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>P/N</TableCell>
            <TableCell>{profile.pn}</TableCell>
            <TableCell>H/PCD</TableCell>
            <TableCell>{profile.hole}/{profile.pcd}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>尺寸</TableCell>
            <TableCell>{profile.diameter}*{profile.width}</TableCell>
            <TableCell>偏移</TableCell>
            <TableCell>{profile.et}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>中心孔直径</TableCell>
            <TableCell>{profile.cb}</TableCell>
            <TableCell>颜色</TableCell>
            <TableCell>{profile.color}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ETA</TableCell>
            <TableCell>{profile.eta}</TableCell>
            <TableCell>Seat</TableCell>
            <TableCell>{profile.seat}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>品牌</TableCell>
            <TableCell>{profile.logo}</TableCell>
            <TableCell>库存</TableCell>
            <TableCell>{profile.quantity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>备注</TableCell>
            <TableCell colSpan={3}>{profile.attention}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>价格</TableCell>
            <TableCell>{format_yuan(profile.price)}</TableCell>
            <TableCell>添加时间</TableCell>
            <TableCell>{dayjs(profile.create_at).format('LL LTS')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { ProfileTable }
