import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import isEmail from 'validator/lib/isEmail';
import { useSnackbar } from 'notistack';
import useTitle from "~/hook/title";
import { put } from './fetch';

export default function Forget() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useTitle('Forget password');

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

  // 提交
  const onSubmit = async data => {
    try {
      data._audit = `${data.email} 找回密码`;

      await put('/forget', new URLSearchParams(data));
      enqueueSnackbar('Please check your email', { variant: 'success' });
      navigate('..', { replace: true });
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  }

  return (
    <Container as='main' maxWidth='sm' sx={{ mb: 8 }}>
      <Paper elevation={3} sx={{ px: 5, py: 4, mt: 5 }}>
        <Stack direction='row' alignItems='center' spacing={1} mb={2}>
          <Typography variant='h4'>Reset your password</Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1} mt={3}>
            <TextField label='Email' fullWidth autoFocus
              required placeholder='Enter the email address you used to register'
              helperText={errors?.email?.message}
              error={errors?.email}
              InputLabelProps={{ shrink: true }}
              {...register('email', {
                required: "Required",
                maxLength: {
                  value: 128, message: 'Exceed max length'
                },
                validate: v => isEmail(v) || 'Invalid',
              })}
            />
            <Typography variant='caption' color='gray'>
              If you don't remember your register email address,
              please call 647-877-7477 or 416-818-5067
            </Typography>
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ mt: 5 }}>
            <LoadingButton variant='contained' type='submit' color='info'
              loading={isSubmitting}>
              Reset Password
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}
