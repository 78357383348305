import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from "react";
import useTitle from "~/hook/title";
import Spec from "./spec";
import Vehicle from "./vehicle";

export default function Home() {
  const [tab, setTab] = useState(0);

  useTitle('Wheels');

  const onTabChange = (e, index) => {
    setTab(index);
  };

  return (
    <Container as='main' maxWidth='full' sx={{ mt: 4, mb: 8 }}>
      <Stack direction='row' spacing={2}>
        <Box sx={{ borderRight: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={onTabChange} orientation='vertical'>
            <Tab label="Vehicle" value={0} sx={{ alignItems: 'flex-end' }} />
            <Tab label="Specs" value={1} sx={{ alignItems: 'flex-end' }} />
            {/* <Tab label="Detail" value={2} sx={{ alignItems: 'flex-end' }} /> */}
          </Tabs>
        </Box>
        {tab === 0 && <Vehicle />}
        {tab === 1 && <Spec />}
        {/* {tab === 2 && <Detail />} */}
      </Stack>
    </Container>
  )
}
