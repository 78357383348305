import { useEffect } from 'react';
import { useNavigate, Link as RouteLink } from 'react-router-dom';
import { useSetRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'notistack';
import progressState from '~/state/progress';
import useTitle from "~/hook/title";
import { useSetCode } from "~/state/code";
import { post } from '~/lib/rest';

export default function Add() {
  const navigate = useNavigate();
  const setProgress = useSetRecoilState(progressState);
  const { enqueueSnackbar } = useSnackbar();

  useHotkeys('esc', () => { navigate('..'); }, { enableOnTags: ["INPUT"] });
  useTitle('添加轮毂');
  useSetCode(200);

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm();

  useEffect(() => {
    (async () => {
      try {
        setProgress(true);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
      }
    })();
  }, [enqueueSnackbar, setProgress]);

  const onSubmit = async data => {
    try {
      data._audit = `添加轮毂 ${data.model}`;

      await post('/wheels/add', new URLSearchParams(data));
      enqueueSnackbar(`添加成功`, { variant: 'success' });
      navigate('..', { replace: true });
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  }

  return (
    <Container as='main' maxWidth='md' sx={{ mb: 4 }}>
      <Paper sx={{ px: 4, py: 3, mt: 5 }}>
        <Stack direction='row' alignItems='center' spacing={1} sx={{ mb: 3 }}>
          <IconButton aria-label='返回' component={RouteLink} to='..'>
            <ArrowBackIcon color='primary' />
          </IconButton>
          <Typography variant='h5'>轮毂资料</Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper variant='outlined' sx={{ px: 4, py: 3 }}>
            <Stack spacing={4}>
              <Stack direction='row' spacing={3}>
                <TextField label='型号(Model)' variant='standard' fullWidth required
                  placeholder='Model'
                  disabled={isSubmitting}
                  helperText={errors?.model?.message}
                  error={errors?.model ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register('model', {
                    required: "不能为空",
                    maxLength: {
                      value: 64, message: '超出最大长度'
                    },
                  })}
                />
                <TextField label='零件编号(P/N)' variant='standard' fullWidth required
                  placeholder='Part Number'
                  disabled={isSubmitting}
                  helperText={errors?.pn?.message}
                  error={errors?.pn ? true : false}
                  inputProps={{ maxLength: 64 }}
                  {...register('pn', {
                    required: "不能为空",
                    maxLength: {
                      value: 64, message: '超出最大长度'
                    },
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='直径' variant='standard' fullWidth required
                  placeholder='Diameter' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.diameter?.message}
                  error={errors?.diameter ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  {...register('diameter', {
                    required: "不能为空",
                  })}
                />
                <TextField label='宽度' variant='standard' fullWidth required
                  placeholder='Width' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.width?.message}
                  error={errors?.width ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  {...register('width', {
                    required: "不能为空",
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='螺柱孔数(Holes)' variant='standard' fullWidth required
                  placeholder='Number of stud holes' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.hole?.message}
                  error={errors?.hole ? true : false}
                  inputProps={{ min: 0 }}
                  {...register('hole', {
                    required: "不能为空",
                  })}
                />
                <TextField label='节圆直径(PCD)' variant='standard' fullWidth required
                  placeholder='Pitch circle diameter' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.pcd?.message}
                  error={errors?.pcd ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  {...register('pcd', {
                    required: "不能为空",
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='偏移(ET)' variant='standard' fullWidth required
                  placeholder='Offset' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.et?.message}
                  error={errors?.et ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  {...register('et', {
                    required: "不能为空",
                  })}
                />
                <TextField label='中心孔直径(CB)' variant='standard' fullWidth required
                  placeholder='Centre bore diameter' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.cb?.message}
                  error={errors?.cb ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  {...register('cb', {
                    required: "不能为空",
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='颜色(Finish)' variant='standard' fullWidth
                  required placeholder='Finish'
                  disabled={isSubmitting}
                  helperText={errors?.color?.message}
                  error={errors?.color ? true : false}
                  {...register('color', {
                    required: "不能为空",
                    maxLength: {
                      value: 128, message: '超出最大长度'
                    },
                  })}
                />
                <TextField label='品牌(Brand/Logo)' variant='standard' fullWidth
                  required placeholder='Logo'
                  disabled={isSubmitting}
                  helperText={errors?.logo?.message}
                  error={errors?.logo ? true : false}
                  {...register('logo', {
                    required: "不能为空",
                    maxLength: {
                      value: 128, message: '超出最大长度'
                    },
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='到货时间(ETA)' variant='standard' fullWidth
                  required placeholder='ETA'
                  disabled={isSubmitting}
                  helperText={errors?.eta?.message}
                  error={errors?.eta ? true : false}
                  {...register('eta', {
                    required: "不能为空",
                    maxLength: {
                      value: 128, message: '超出最大长度'
                    },
                  })}
                />
                <TextField label='Seat' variant='standard' fullWidth
                  required placeholder='Seat'
                  disabled={isSubmitting}
                  helperText={errors?.seat?.message}
                  error={errors?.seat ? true : false}
                  {...register('seat', {
                    required: "不能为空",
                    maxLength: {
                      value: 128, message: '超出最大长度'
                    },
                  })}
                />
              </Stack>
              <Stack direction='row' spacing={3}>
                <TextField label='库存(Quantity)' variant='standard' fullWidth
                  required placeholder='Quantity' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.quantity?.message}
                  error={errors?.quantity ? true : false}
                  inputProps={{ min: 0 }}
                  {...register('quantity', {
                    required: "不能为空",
                  })}
                />
                <TextField label='价格(Price)' variant='standard' fullWidth
                  required placeholder='指导价格' type='number'
                  disabled={isSubmitting}
                  helperText={errors?.price?.message}
                  error={errors?.price ? true : false}
                  inputProps={{ min: 0, step: 0.01 }}
                  {...register('price', {
                    required: "不能为空",
                  })}
                />
              </Stack>
              <TextField label='Attention' variant='standard' fullWidth
                placeholder='Attention'
                disabled={isSubmitting}
                helperText={errors?.attention?.message}
                error={errors?.attention ? true : false}
                {...register('attention', {
                  maxLength: {
                    value: 256, message: '超出最大长度'
                  },
                })}
              />
            </Stack>
          </Paper>
          <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ mt: 4 }}>
            <Button color='secondary' disabled={isSubmitting}
              onClick={() => { navigate('..') }}>
              取消
            </Button>
            <LoadingButton variant='contained' type='submit'
              loading={isSubmitting}>
              提交
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}
