import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import isEmail from 'validator/lib/isEmail';
import { useSnackbar } from 'notistack';
import progressState from '~/state/progress';
import useTitle from "~/hook/title";
import { get, post } from './fetch';

export default function Register() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const setProgress = useSetRecoilState(progressState);
  const [provinces, setProvinces] = useState([]);

  useTitle('Register Account');

  const {
    register, handleSubmit, formState: {
      errors, isSubmitting
    }
  } = useForm();

  useEffect(() => {
    (async () => {
      try {
        setProgress(true);
        const resp = await get('/provinces');
        setProvinces(resp.provinces || []);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setProgress(false);
      }
    })();
  }, [enqueueSnackbar, setProgress]);

  // 提交
  const onSubmit = async data => {
    try {
      if (data.password !== data.password2) {
        return enqueueSnackbar('Password dismatch');
      }
      data._audit = `注册用户 ${data.firstname}`;

      await post('/register', new URLSearchParams(data));
      enqueueSnackbar('Register successed', { variant: 'success' });
      navigate('..', { replace: true });
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  }

  return (
    <Container as='main' maxWidth='md' sx={{ mb: 8 }}>
      <Paper elevation={3} sx={{ px: 5, py: 4, mt: 5 }}>
        <Stack direction='row' alignItems='center' spacing={1} mb={5}>
          <Typography variant='h4'>Create an Account</Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Typography variant='h6'>Personal Information</Typography>
            <Stack direction='row' spacing={3} mt={2}>
              <TextField label='First Name' variant='standard' fullWidth
                required placeholder='First Name'
                helperText={errors?.firstname?.message}
                error={errors?.firstname}
                {...register('firstname', {
                  required: "Required",
                  maxLength: {
                    value: 64, message: 'Exceed max length'
                  },
                })}
              />
              <TextField label='Last Name' variant='standard' fullWidth
                required placeholder='Last Name'
                helperText={errors?.lastname?.message}
                error={errors?.lastname}
                {...register('lastname', {
                  required: "Required",
                  maxLength: {
                    value: 64, message: 'Exceed max length'
                  },
                })}
              />
            </Stack>
            <Typography variant='h6' sx={{ mt: 5 }}>Login Information</Typography>
            <TextField label='Email' variant='standard' fullWidth
              required placeholder='Email' margin='normal'
              helperText={errors?.email?.message}
              error={errors?.email}
              {...register('email', {
                required: "Required",
                maxLength: {
                  value: 128, message: 'Exceed max length'
                },
                validate: v => isEmail(v) || 'Invalid',
              })}
            />
            <Stack direction='row' spacing={3} mt={2}>
              <TextField label='Password' variant='standard' fullWidth
                required placeholder='New Password' type='password'
                autoComplete='new-password'
                helperText={errors?.password?.message}
                error={errors?.password}
                {...register('password', {
                  required: "Required",
                  maxLength: {
                    value: 64, message: 'Exceed max length'
                  },
                })}
              />
              <TextField label='Password Confirm' variant='standard' fullWidth
                required placeholder='Password confirm' type='password'
                autoComplete='new-password'
                helperText={errors?.password2?.message}
                error={errors?.password2}
                {...register('password2', {
                  required: "Required",
                  maxLength: {
                    value: 64, message: 'Exceed max length'
                  },
                })}
              />
            </Stack>
            <Typography variant='h6' sx={{ mt: 5 }}>
              Trade Customer Registration
            </Typography>
            <TextField label='Business Name' variant='standard' fullWidth required
              placeholder='Business name' margin='normal'
              helperText={errors?.company?.message}
              error={errors?.company}
              {...register('company', {
                required: "Required",
                maxLength: {
                  value: 128, message: 'Exceed max length'
                },
              })}
            />
            <Stack direction='row' spacing={3} mt={2}>
              <TextField label='Telephone' variant='standard' fullWidth
                required placeholder='Telephone'
                helperText={errors?.tel?.message}
                error={errors?.tel}
                {...register('tel', {
                  required: "Required",
                  maxLength: {
                    value: 32, message: 'Exceed max length'
                  },
                })}
              />
              <TextField label='Fax' variant='standard' fullWidth
                placeholder='Fax'
                helperText={errors?.fax?.message}
                error={errors?.fax}
                {...register('fax', {
                  maxLength: {
                    value: 32, message: 'Exceed max length'
                  },
                })}
              />
              <TextField label='Cell' variant='standard' fullWidth
                placeholder='Cell'
                helperText={errors?.cell?.message}
                error={errors?.cell}
                {...register('cell', {
                  maxLength: {
                    value: 32, message: 'Exceed max length'
                  },
                })}
              />
            </Stack>
            <TextField label='Street Address' variant='standard' fullWidth
              required placeholder='Street Address' margin='normal'
              helperText={errors?.address1?.message}
              error={errors?.address1}
              {...register('address1', {
                required: "Required",
                maxLength: {
                  value: 128, message: 'Exceed max length'
                },
              })}
            />
            <Stack direction='row' spacing={3} mt={2}>
              <TextField label='Country' variant='standard' fullWidth
                required select defaultValue='Canada'
                {...register('country', { required: "Required" })}>
                <MenuItem value='Canada'>Canada</MenuItem>
              </TextField>
              <TextField label='State or Province' variant='standard' fullWidth
                required select defaultValue=''
                {...register('province', { required: "Required" })}>
                {provinces.map(p => (
                  <MenuItem key={p} value={p}>{p}</MenuItem>
                ))}
              </TextField>
            </Stack>
            <Stack direction='row' spacing={3} mt={2}>
              <TextField label='City' variant='standard' fullWidth
                required placeholder='city'
                helperText={errors?.city?.message}
                error={errors?.city}
                {...register('city', {
                  required: "Required",
                  maxLength: {
                    value: 128, message: 'Exceed max length'
                  },
                })}
              />
              <TextField label='ZIP/Postal Code' variant='standard' fullWidth
                placeholder='Postal code'
                helperText={errors?.postal?.message}
                error={errors?.postal}
                {...register('postal', {
                  maxLength: {
                    value: 32, message: 'Exceed max length'
                  },
                })}
              />
            </Stack>
            <TextField label='HST #' variant='standard' fullWidth
              required placeholder='HST #' margin='normal'
              helperText={errors?.tax?.message}
              error={errors?.tax}
              {...register('tax', {
                maxLength: {
                  value: 128, message: 'Exceed max length'
                },
              })}
            />
          </Stack>
          <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ mt: 5 }}>
            <LoadingButton variant='contained' type='submit' color='info'
              loading={isSubmitting}>
              Register
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}
