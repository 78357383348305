import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Link as RouteLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSnackbar } from 'notistack';
import isEmail from 'validator/lib/isEmail';
import { put } from './fetch';
import memberState from './state';

export default function Login() {
  const setMember = useSetRecoilState(memberState);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordHide, setPasswordHide] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 登录名改变
  const onEmailChange = e => {
    setEmail(e.target.value);
  }

  // 登录名框回车
  const onEmailKeyDown = e => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  }

  // 密码改变
  const onPasswordChange = e => {
    setPassword(e.target.value);
  }

  // 密码框回车
  const onPasswordKeyDown = e => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  }

  // 提交认证
  const onSubmit = async () => {
    if (!email || !password) {
      return enqueueSnackbar('Please input email or password', {
        variant: 'warning', preventDuplicate: true,
      });
    }
    if (!isEmail(email)) {
      return enqueueSnackbar('Email format error', { variant: 'warning' });
    }
    try {
      setSubmitting(true);

      const resp = await put('/login', new URLSearchParams({ email, password }));
      setSubmitting(false);

      handleClose();

      // 登录成功
      localStorage.setItem("member", resp.token);
      if (resp.member) {
        resp.member.cart_count = resp.cart_count || 0;
        setMember(resp.member);
      }
      window.location.reload();
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      setSubmitting(false);
    }
  }

  return (
    <div>
      <Button color="inherit" onClick={handleClickOpen}>Login</Button>
      <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
        <DialogContent>
          <Typography as='h1' variant='h5' sx={{ mt: 1 }}>Welcome, please login</Typography>
          <TextField required label='Email' placeholder="Your email"
            fullWidth autoComplete="username" type='email'
            variant='standard' value={email} onChange={onEmailChange}
            onKeyDown={onEmailKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            sx={{ mt: 3 }}
          />
          <TextField required label='Password' placeholder="Password"
            fullWidth autoComplete="password"
            variant='standard' type={passwordHide ? 'password' : 'text'}
            value={password} onChange={onPasswordChange}
            onKeyDown={onPasswordKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <KeyIcon fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="显示密码" size='small' onClick={() => {
                    setPasswordHide(!passwordHide);
                  }}>
                    {passwordHide ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mt: 4 }}
          />
          <Stack direction='row' justifyContent='flex-end' mt={1}>
            <Link underline='hover' component={RouteLink} to='forget' variant='body2'
              onClick={handleClose}>
              Forget Password
            </Link>
          </Stack>
          <Button fullWidth variant="contained" size="large" sx={{ my: 4 }}
            onClick={onSubmit} disabled={submitting}>
            Login
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}
