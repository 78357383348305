import { useState } from 'react';
import { useForm } from "react-hook-form";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { post } from '~/lib/rest';

export default function Add(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm();
  const { requestRefresh } = props;

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = async data => {
    try {
      data._audit = `增加客户折扣规则 ${data.name}`;

      await post('/member/level/add', new URLSearchParams(data));
      enqueueSnackbar(`${data.name} 添加成功`, { variant: 'success' });
      requestRefresh();
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  }

  return (
    <>
      <Button variant="outlined" size="small" startIcon={<AddIcon />} onClick={onOpen}>
        添加规则
      </Button>
      <Dialog onClose={onClose} open={open} maxWidth='sm' fullWidth>
        <DialogTitle>添加折扣规则</DialogTitle>
        <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper variant='outlined' sx={{ px: 4, py: 3 }}>
              <Stack spacing={4}>
                <TextField label='规则名称' variant='standard' fullWidth required
                  autoFocus
                  placeholder='便于识别和记忆'
                  disabled={isSubmitting}
                  helperText={errors?.name?.message}
                  error={errors?.name ? true : false}
                  inputProps={{ maxLength: 32 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('name', {
                    required: "不能为空",
                    maxLength: {
                      value: 32, message: '超出最大长度'
                    },
                  })}
                />
                <TextField label='WIC 轮毂折扣' variant='standard' fullWidth required
                  placeholder='0~1 之间的数字，例如 0.85 表示 85 折'
                  type='number' disabled={isSubmitting}
                  helperText={errors?.discount1?.message}
                  error={errors?.discount1 ? true : false}
                  inputProps={{ max: 1, min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('discount1', {
                    required: "不能为空",
                  })}
                />
                <TextField label='FTR 轮毂折扣' variant='standard' fullWidth required
                  placeholder='0~1 之间的数字，例如 0.85 表示 85 折'
                  type='number' disabled={isSubmitting}
                  helperText={errors?.discount2?.message}
                  error={errors?.discount2 ? true : false}
                  inputProps={{ max: 1, min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('discount2', {
                    required: "不能为空",
                  })}
                />
                <TextField label='配件折扣' variant='standard' fullWidth required
                  placeholder='0~1 之间的数字，例如 0.85 表示 85 折'
                  type='number' disabled={isSubmitting}
                  helperText={errors?.discount3?.message}
                  error={errors?.discount3 ? true : false}
                  inputProps={{ max: 1, min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  {...register('discount3', {
                    required: "不能为空",
                  })}
                />
              </Stack>
            </Paper>
            <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ mt: 4 }}>
              <Button color='secondary' disabled={isSubmitting} onClick={onClose}>
                取消
              </Button>
              <LoadingButton variant='contained' type='submit' color='info'
                loading={isSubmitting}>
                提交
              </LoadingButton>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}
