import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from "@mui/material/Container";
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ImageGallery from "~/comp/image-gallery";
import SearchInput from '~/comp/search-input';
import usePageData from '~/hook/pagedata';
import useTitle from "~/hook/title";
import { format_yuan } from '~/lib/format';
import { get } from '~/lib/rest';
import { useSetCode } from "~/state/code";
import ImagePicker from './imagepicker';
import MoreMenu from './menu';

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = usePageData();
  const [keyword, setKeyword] = useState([]);
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(pageData('rowsPerPage') || 10);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);

  useTitle('螺母管理');
  useSetCode(221);

  const requestRefresh = () => {
    setRefresh(true);
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const q = new URLSearchParams({ page, rows, keyword });
        const resp = await get('/nuts/list?' + q.toString());
        setList(resp.list || []);
        setCount(resp.count || 0);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    })();
  }, [enqueueSnackbar, page, rows, keyword, refresh]);

  // 搜索
  const onKeywordChange = value => {
    setKeyword(value);
    setPage(0);
  }

  // 页面改变
  const onPageChange = (e, newPage) => {
    setPage(newPage);
  }

  // 每页行数改变
  const onRowsPerPageChange = e => {
    const rows = parseInt(e.target.value, 10);

    setRows(rows);
    setPage(0);
    setPageData('rowsPerPage', rows);
  }

  return (
    <Container as='main' maxWidth='lg' sx={{ mb: 4 }}>
      <Toolbar sx={{ mt: 2 }} disableGutters>
        <Stack direction='row' spacing={2}>
          <SearchInput isLoading={loading} onChange={onKeywordChange}
            placeholder={count > 0 ? `在 ${count} 条记录中搜索: pn/brand` : '搜索...'}
            sx={{ minWidth: 300 }}
          />
        </Stack>
        <Box flex={1} />
        <Button variant='outlined' size='small' LinkComponent={Link} to='add'
          startIcon={<AddIcon />}>
          添加
        </Button>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding='none'></TableCell>
            <TableCell align="center">Brand</TableCell>
            <TableCell align="center">PN</TableCell>
            <TableCell align="center">Hex</TableCell>
            <TableCell align="center">Seat</TableCell>
            <TableCell align="center">Thread</TableCell>
            <TableCell align="center">ShankLength</TableCell>
            <TableCell align="center">Finish</TableCell>
            <TableCell align="center">ETA</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center" padding='checkbox'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => (
            <TableRow key={index} hover>
              <TableCell align="center" padding='none' width={1}>
                <Stack direction='row' spacing={'4px'}>
                  <ImageGallery images={[row.image, row.image2, row.image3]} />
                </Stack>
                <ImagePicker item={row} index={1} requestRefresh={requestRefresh} />
                <ImagePicker item={row} index={2} requestRefresh={requestRefresh} />
                <ImagePicker item={row} index={3} requestRefresh={requestRefresh} />
              </TableCell>
              <TableCell align="center">{row.brand}</TableCell>
              <TableCell align="center">{row.pn}</TableCell>
              <TableCell align="center">{row.hex}</TableCell>
              <TableCell align="center">{row.seat}</TableCell>
              <TableCell align="center">{row.thread}</TableCell>
              <TableCell align="center">{row.shanklength}</TableCell>
              <TableCell align="center">{row.finish}</TableCell>
              <TableCell align="center">{row.eta}</TableCell>
              <TableCell align="center">{format_yuan(row.price)}</TableCell>
              <TableCell align="center">{row.quantity}</TableCell>

              <TableCell align="center" padding='checkbox'>
                <MoreMenu item={row} requestRefresh={requestRefresh} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              colSpan={20}
              count={count}
              rowsPerPage={rows}
              page={page}
              SelectProps={{ inputProps: { 'aria-label': '每页行数' } }}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Container>
  )
}
