import { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import NotFound from "~/comp/notfound";
import Home from './home';
import Level from './level';
import Profile from './profile';
import Modify from './modify';
import NLevel from './nlevel';

export default function Member() {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='level' element={<Level />} />
        <Route path='profile' element={<Profile />} />
        <Route path='modify' element={<Modify />} />
        <Route path='nlevel' element={<NLevel />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  )
}
