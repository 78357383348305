import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import KeyIcon from '@mui/icons-material/Key';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AppBar from "@mui/material/AppBar";
import Badge from '@mui/material/Badge';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from '@mui/material/IconButton';
import Link from "@mui/material/Link";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { Route, Link as RouteLink, Routes, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import useColorModeContent from "~/hook/colormode";
import Banner from '~/img/banner-dark.png';
import Accessories from "./accessories";
import Bolts from "./bolts";
import Cart from "./cart";
import Contact from "./contact";
import { put } from './fetch';
import Forget from "./forget";
import Login from "./login";
import Nuts from "./nuts";
import Orders from "./orders";
import Password from "./password";
import Register from "./register";
import memberState, { homeTabState } from "./state";
import Wheel from "./wheel";

export default function Home() {
  const theme = useTheme();
  const colorMode = useColorModeContent();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [member, setMember] = useRecoilState(memberState);
  const [homeTab, setHomeTab] = useRecoilState(homeTabState);

  useEffect(() => {
    (async () => {
      const s = localStorage.getItem("member");
      if (s) {
        try {
          const resp = await put('/auth');
          const m = resp.member;
          if (m) {
            m.cart_count = resp.cart_count;
          }
          setMember(m);
        } catch (err) {
          enqueueSnackbar(err.message, { variant: 'error' });
        }
      }
    })();
  }, [enqueueSnackbar, setMember]);

  const onTabSwitch = index => {
    switch (index) {
      case 'wheels':
        navigate('/home');
        break;
      case 'accessories':
        navigate('/home/accessories');
        break;
      case 'nuts':
        navigate('/home/nuts');
        break;
      case 'bolts':
        navigate('/home/bolts');
        break;
      default:
        break;
    }
    setHomeTab(index);
  }

  return (
    <Box>
      <Stack direction='row' alignItems='center' justifyContent='center' p={1}
        spacing={3} bgcolor='black' color='white'>
        {/* <Stack direction='row' spacing={1} alignItems='baseline'>
          <Typography variant="body2">Call:</Typography>
          <Link variant="button" color='yellow' href='tel:6478777477' underline='hover'>
            647-877-7477
          </Link>
          <Typography variant="body2">|</Typography>
          <Link variant="button" color='yellow' href='tel:4168185067' underline='hover'>
            416-818-5067
          </Link>
        </Stack> */}
        {/* <Typography color='yellow'>
          All wheels will arrive on Oct 28th,
          the pre order will be delivered betwent Oct 28 to Nov 8.
        </Typography> */}
        <Stack direction='row' spacing={1} alignItems='baseline'>
          <Typography variant="body2">EMail:</Typography>
          <Link variant="subtitle2" color='yellow' href='mailto:info@ftrwheels.com'
            underline='hover'>
            info@ftrwheels.com
          </Link>
        </Stack>
        <Link variant="subtitle2" color='inherit' underline='hover' href='/member'
          target='_blank'>
          Administrator
        </Link>
      </Stack>
      <AppBar position="static">
        <Toolbar>
          <Stack direction='row' spacing={2} width='40%'>
            <Link component='button' variant='body1' underline='hover'
              fontWeight={homeTab === 'wheels' ? 'bold' : 'normal'}
              color={homeTab === 'wheels' ? 'yellow' : 'inherit'}
              onClick={() => onTabSwitch('wheels')}>
              Wheels
            </Link>
            <Link component='button' variant='body1' underline='hover'
              fontWeight={homeTab === 'accessories' ? 'bold' : 'normal'}
              color={homeTab === 'accessories' ? 'yellow' : 'inherit'}
              onClick={() => onTabSwitch('accessories')}>
              HUB RING
            </Link>
            <Link component='button' variant='body1' underline='hover'
              fontWeight={homeTab === 'nuts' ? 'bold' : 'normal'}
              color={homeTab === 'nuts' ? 'yellow' : 'inherit'}
              onClick={() => onTabSwitch('nuts')}>
              LUG NUT
            </Link>
            <Link component='button' variant='body1' underline='hover'
              fontWeight={homeTab === 'bolts' ? 'bold' : 'normal'}
              color={homeTab === 'bolts' ? 'yellow' : 'inherit'}
              onClick={() => onTabSwitch('bolts')}>
              LUG BOLT
            </Link>
          </Stack>
          <Stack flex={1} alignItems='center'>
            <img src={Banner} alt='Banner' height={28}
              style={{ objectFit: 'contain', }}
            />
          </Stack>
          <Stack width='40%' direction='row' justifyContent='flex-end'>
            {member?.uuid ? (
              <Stack direction='row' spacing={2}>
                <Tooltip title='My Shopping Cart' arrow>
                  <IconButton color='inherit' LinkComponent={RouteLink} to='cart'
                    onClick={() => setHomeTab('cart')}>
                    <Badge max={99} badgeContent={member.cart_count} color="secondary">
                      <ShoppingCartIcon fontSize="small" sx={{
                        color: homeTab === 'cart' ? 'yellow' : "inherit"
                      }} />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <MemberMenu />
              </Stack>
            ) :
              <Stack direction='row' spacing={2}>
                <Login />
                <Button LinkComponent={RouteLink} to='register' color="secondary"
                  variant="contained" onClick={() => setHomeTab('register')}>
                  Register
                </Button>
              </Stack>
            }
            <Contact />
            <Tooltip title='Dark/Light' arrow>
              <IconButton aria-label="Light/Dark"
                onClick={colorMode.toggleColorMode} color='inherit'>
                {theme.palette.mode === 'dark' ?
                  <LightModeIcon fontSize="small" /> :
                  <DarkModeIcon fontSize="small" />
                }
              </IconButton>
            </Tooltip>
          </Stack>
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path='register/*' element={<Register />} />
        <Route path='forget/*' element={<Forget />} />
        <Route path='password/*' element={<Password />} />
        <Route path='cart/*' element={<Cart />} />
        <Route path='orders/*' element={<Orders />} />
        <Route path='accessories/*' element={<Accessories />} />
        <Route path='nuts/*' element={<Nuts />} />
        <Route path='bolts/*' element={<Bolts />} />
        <Route path='*' element={<Wheel />} />
      </Routes>
    </Box>
  )
}

function MemberMenu() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [member, setMember] = useRecoilState(memberState);
  const setHomeTab = useSetRecoilState(homeTabState);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onMyOrders = () => {
    setHomeTab('orders');
    navigate('orders');
  }

  const onChangePassword = () => {
    navigate('password');
  }

  const onLogout = async () => {
    try {
      await confirm({
        title: 'Confirm',
        description: `Are you sure you want to log out？`,
        confirmationText: 'Yes',
        confirmationButtonProps: { color: 'warning' },
        cancellationText: 'No',
      });

      localStorage.removeItem('member');
      setMember(null);
    } catch (err) {
      if (err) {
        enqueueSnackbar(err.message);
      }
    }
  }

  return (
    <div>
      <Button color='inherit' onClick={onOpen}
        endIcon={<ArrowDropDownIcon fontSize="small" />}>
        {member?.company?.length > 20 ?
          member?.company?.substr(0,20) + '...' : member?.company
        }
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose} onClick={onClose}>
        <MenuItem onClick={onMyOrders}>
          <ListItemIcon>
            <ReceiptLongIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>My Orders</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onChangePassword}>
          <ListItemIcon>
            <KeyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Password</ListItemText>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" color='error' />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
