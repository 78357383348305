import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

export default function Contact() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="inherit" onClick={handleClickOpen}>Contact</Button>
      <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
        <DialogTitle>Contact us</DialogTitle>
        <DialogContent sx={{fontSize: '0.9em'}}>
          <Grid container spacing={1}>
            <Grid item xs={4}>Tel:</Grid>
            <Grid item xs={8}>
              <Stack spacing={0}>
                <Link href='tel:6478777477' underline='hover'>647-877-7477</Link>
                <Link href='tel:4168185067' underline='hover'>416-818-5067</Link>
              </Stack>
            </Grid>
            <Grid item xs={4}>Email:</Grid>
            <Grid item xs={8}>
              <Link href='mailto:info@ftrwheels.com' underline='hover'>
                info@ftrwheels.com
              </Link>
            </Grid>
            <Grid item xs={4}>Address:</Grid>
            <Grid item xs={8}>
              3175 14th Ave unit2, Markham, ON L3R 5M1
            </Grid>
            <Grid item xs={4}>Business Hours:</Grid>
            <Grid item xs={8}>
              <Grid container spacing={0}>
                <Grid item xs={3}>Mon-Fri:</Grid>
                <Grid item xs={9}>9:30 ~ 18:00</Grid>
                <Grid item xs={3}>Sat:</Grid>
                <Grid item xs={9}>10:30 ~ 16:00</Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
