import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import FitbitIcon from "@mui/icons-material/Fitbit";
import SubIcon from "@mui/icons-material/Remove";
import LoadingButton from "@mui/lab/LoadingButton";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import SearchInput from "~/comp/search-input";
import ZoomImage from "~/comp/zoomimage";
import useTitle from "~/hook/title";
import { format_yuan } from "~/lib/format";
import { del, post } from "./fetch";
import memberState from "./state";

function ptypeName(ptype) {
  const ptypeNames = {
    1: "Wheel",
    2: "Hub Ring",
    3: "Lug Nut",
    4: "Lug Bolt",
  };
  return ptypeNames[ptype];
}

export default function Cart() {
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [member, setMember] = useRecoilState(memberState);
  const [keyword, setKeyword] = useState([]);
  const [ptype, setPtype] = useState("");
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(0);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [refresh, setRefresh] = useState(true);

  useTitle("My Cart");

  useEffect(() => {
    (async () => {
      try {
        if (refresh) {
          setLoading(true);

          const resp = await post(
            "/cart/list",
            new URLSearchParams({
              keyword,
              ptype,
            })
          );
          setList(resp.list || []);
          setCount(resp.count || 0);
          setSelected(0);
          setAmount(0);

          // 更新全局状态
          if (member && !ptype) {
            const m = { ...member };
            m.cart_count = resp?.list?.length || 0;
            setMember(m);
          }
        }
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    })();
  }, [enqueueSnackbar, keyword, ptype, member, setMember, refresh]);

  // 搜索
  const onKeywordChange = (value) => {
    setKeyword(value);
    setRefresh(true);
  };

  const onPtypeChange = (e, v) => {
    if (v !== null) {
      setPtype(v);
      setRefresh(true);
    }
  };

  // 全选/全不选
  const onSelectAllClick = (e) => {
    const checked = e.target.checked;
    let amt = 0;

    const newlist = list.map((item) => {
      item._checked = checked;
      amt += item.amount;
      return item;
    });
    setList(newlist);
    setSelected(checked ? newlist.length : 0);
    setAmount(checked ? Math.round(amt * 100) / 100 : 0);
  };

  // 单个选择/不选
  const onRowClick = (row) => {
    const checked = row._checked ? true : false;
    let amt = 0;

    const newlist = list.map((item) => {
      if (item.uuid === row.uuid) {
        item._checked = !checked;
        amt = item.amount;
      }
      return item;
    });
    setList(newlist);
    setSelected(checked ? selected - 1 : selected + 1);
    const newamt = checked ? amount - amt : amount + amt;
    setAmount(Math.round(newamt * 100) / 100);
  };

  // 修改数量
  const onCountChange = async (e, row, count) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (row.count + count > 0) {
        setDisabled(true);

        await post(
          "/cart/count",
          new URLSearchParams({
            uuid: row.uuid,
            count: row.count + count,
          })
        );
        setRefresh(true);
      }
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setDisabled(false);
    }
  };

  // Remove
  const onRemoveClick = async (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await confirm({
        title: "Please confirm",
        description: `Are you sure you want to remove it？`,
        confirmationText: "Remove",
        confirmationButtonProps: { color: "error" },
        cancellationText: "Cancel",
      });
      setDisabled(true);

      const params = new URLSearchParams({ uuid: row.uuid });
      await del("/cart/del?" + params.toString());
      enqueueSnackbar("Item has been removed", { variant: "success" });
      setRefresh(true);
    } catch (err) {
      if (err) {
        enqueueSnackbar(err.message);
      }
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Container as="main" maxWidth="lg" sx={{ mb: 8 }}>
      <Toolbar sx={{ mt: 2 }} disableGutters>
        <Typography variant="h6" sx={{ flex: 1 }}>
          My Cart
        </Typography>
        <Stack direction="row" spacing={2}>
          <SearchInput
            isLoading={loading}
            onChange={onKeywordChange}
            placeholder={
              count > 0 ? `Search in ${count} records...` : "Search..."
            }
            sx={{ minWidth: 300 }}
          />
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={ptype}
            onChange={onPtypeChange}
          >
            <ToggleButton value="" sx={{ py: "4px" }}>
              All
            </ToggleButton>
            <ToggleButton value="1" sx={{ py: "4px" }}>
              Wheel
            </ToggleButton>
            <ToggleButton value="2" sx={{ py: "4px" }}>
              HUB RING
            </ToggleButton>
            <ToggleButton value="2" sx={{ py: "4px" }}>
              Lug Nut
            </ToggleButton>
            <ToggleButton value="2" sx={{ py: "4px" }}>
              Lug Bolt
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Toolbar>
      <AppBar
        color="default"
        elevation={0}
        enableColorOnDark
        sx={{ position: "sticky", top: 0 }}
      >
        <Toolbar>
          <Stack flex={1} direction="row" alignItems="center" spacing={3}>
            <AvatarGroup total={selected} max={11}>
              {list.map(
                (item) =>
                  item._checked && (
                    <Avatar
                      key={item.uuid}
                      alt="Img"
                      src={`/image/?u=${item.image}`}
                    />
                  )
              )}
            </AvatarGroup>
            <Typography variant="subtitle2" sx={{ flex: 1 }}>
              {selected === 0
                ? "No item selected"
                : `${selected} items selected`}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="baseline">
              <Typography variant="button">Total Amount:</Typography>
              <Typography fontSize="large" fontWeight="bold" color="orangered">
                ${format_yuan(amount)}
              </Typography>
            </Stack>
            <OrderButton
              disabled={selected === 0}
              list={list}
              setRefresh={setRefresh}
            />
          </Stack>
        </Toolbar>
      </AppBar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                onChange={onSelectAllClick}
                checked={selected === count}
                indeterminate={selected > 0 && selected < count}
              />
            </TableCell>
            <TableCell align="center" padding="none"></TableCell>
            <TableCell align="center">Type</TableCell>
            <TableCell align="center">P/N</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Count</TableCell>
            <TableCell align="center">SubTotal</TableCell>
            <TableCell align="center">Tax</TableCell>
            <TableCell align="center">Amount</TableCell>
            <TableCell align="center" padding="none"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <TableRow
              key={row.uuid}
              hover
              selected={row._checked}
              onClick={() => onRowClick(row)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={row._checked ? true : false}
                />
              </TableCell>
              <TableCell align="center" padding="none">
                {row.image && <ZoomImage src={`/image/?u=${row.image}`} />}
              </TableCell>
              <TableCell align="center">
                {ptypeName(row.ptype)}
              </TableCell>
              <TableCell align="center">{row.comment}</TableCell>
              <TableCell align="center">{format_yuan(row.price)}</TableCell>
              <TableCell align="center">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton
                    size="small"
                    color="error"
                    disabled={disabled || row.count <= 1}
                    onClick={(e) => onCountChange(e, row, -1)}
                  >
                    <SubIcon fontSize="small" />
                  </IconButton>
                  <Typography variant="body2">{row.count}</Typography>
                  <IconButton
                    size="small"
                    color="success"
                    disabled={disabled}
                    onClick={(e) => onCountChange(e, row, 1)}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </TableCell>
              <TableCell align="center">{format_yuan(row.total)}</TableCell>
              <TableCell align="center">{format_yuan(row.tax)}</TableCell>
              <TableCell align="center">{format_yuan(row.amount)}</TableCell>
              <TableCell align="center" padding="none">
                <Tooltip title="Remove the item" arrow>
                  <IconButton
                    size="small"
                    color="error"
                    disabled={disabled}
                    onClick={(e) => onRemoveClick(e, row)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {list.length === 0 && (loading ? <Placeholder /> : <Empty />)}
    </Container>
  );
}

function OrderButton(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { disabled, list, setRefresh } = props;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // 提交
  const onSubmit = async (data) => {
    try {
      const items = [];

      for (let i = 0; i < list.length; i++) {
        if (list[i]._checked) {
          items.push(list[i].uuid);
        }
      }
      data.items = items;

      await post("/cart/order", new URLSearchParams(data));

      enqueueSnackbar("Your order has been submitted", { variant: "success" });
      handleClose();
      setRefresh(true);
    } catch (err) {
      enqueueSnackbar(err.message);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        Order
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Order</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack flex={1} spacing={3} mt={3}>
              <TextField
                label="PO#"
                fullWidth
                placeholder="po#, optional"
                helperText={errors?.po?.message}
                error={errors?.po}
                {...register("po", {
                  maxLength: {
                    value: 128,
                    message: "Exceed max length",
                  },
                })}
              />
              <TextField
                label="Note"
                fullWidth
                multiline
                rows={3}
                placeholder="optional"
                helperText={errors?.note?.message}
                error={errors?.note}
                {...register("note", {
                  maxLength: {
                    value: 256,
                    message: "Exceed max length",
                  },
                })}
              />
              <Stack alignItems="flex-end">
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="secondary"
                  loading={isSubmitting}
                >
                  Order
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Empty() {
  return (
    <Stack alignItems="center" p={6} spacing={2}>
      <FitbitIcon sx={{ fontSize: 96, color: "#8882" }} />
      <Typography variant="body2" color="gray">
        There are no items in cart
      </Typography>
    </Stack>
  );
}

function Placeholder() {
  return (
    <Stack p={2}>
      <Typography variant="h4" width="100%">
        <Skeleton />
      </Typography>
      <Typography variant="h4" width="100%">
        <Skeleton />
      </Typography>
      <Typography variant="h4" width="100%">
        <Skeleton />
      </Typography>
    </Stack>
  );
}
