import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { useSnackbar } from 'notistack';
import { get, post } from '~/lib/rest';
import { ProfileTable } from './profile';

export default function Check(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [levels, setLevels] = useState([]);
  const [profile, setProfile] = useState({});
  const [level, setLevel] = useState('');
  const [taxrate, setTaxRate] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const { item, requestRefresh } = props;

  useEffect(() => {
    (async () => {
      try {
        const params = new URLSearchParams({ uuid: item.uuid });
        const resp = await get('/member/profile?' + params.toString());
        setProfile(resp?.profile || {});

        const resp2 = await get('/member/level/list');
        setLevels(resp2.list || []);
      } catch (err) {
        enqueueSnackbar(err.message);
      }
    })();
  }, [enqueueSnackbar, item.uuid]);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setLevel('');
    setOpen(false);
  };

  const onLevelChange = e => {
    setLevel(e.target.value);
  }

  const onTaxRateChange = e => {
    setTaxRate(e.target.value);
  }

  const onCheck = async () => {
    try {
      if (!level) {
        return enqueueSnackbar('请选择折扣规则', { variant: 'warning' });
      }
      if (!taxrate) {
        return enqueueSnackbar('请填写税率', { variant: 'warning' });
      }
      setSubmitting(true);

      await post('/member/check', new URLSearchParams({
        uuid: item.uuid, level, taxrate
      }));
      requestRefresh();
      onClose();
    } catch (err) {
      enqueueSnackbar(err.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Tooltip title='等待审核' arrow>
        <IconButton onClick={onOpen}>
          <AccessTimeIcon fontSize='small' color='warning' />
        </IconButton>
      </Tooltip>
      <Dialog onClose={onClose} open={open} maxWidth='md' fullWidth scroll='body'>
        <DialogContent>
          <ProfileTable profile={profile} ischeck />
          <Stack direction='row' spacing={2} mt={4}>
          <TextField required fullWidth select label="客户折扣规则"
            value={level} onChange={onLevelChange}>
            {levels.map((option) => (
              <MenuItem key={option.uuid} value={option.uuid}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField label='税率' fullWidth required type='number'
            placeholder='百分比，例如 13 代表 13%'
            value={taxrate} onChange={onTaxRateChange}
            inputProps={{ max: 99, min: 0 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button color='warning' onClick={onClose}>取消</Button>
          <Button variant='contained' disabled={submitting} onClick={onCheck}>
            通过审核
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
