import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Container from "@mui/material/Container";
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import CheckIcon from '@mui/icons-material/Check';
import { useSnackbar } from 'notistack';
import SearchInput from '~/comp/search-input';
import TimeAgo from '~/comp/timeago';
import useTitle from "~/hook/title";
import usePageData from '~/hook/pagedata';
import { useSetCode } from "~/state/code";
import { get } from '~/lib/rest';
import Check from './check';
import MoreMenu from './menu';

export default function Home() {
  const { enqueueSnackbar } = useSnackbar();
  const [pageData, setPageData] = usePageData();
  const [keyword, setKeyword] = useState([]);
  const [count, setCount] = useState(0);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(pageData('rowsPerPage') || 10);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [checked, setChecked] = useState('');

  useTitle('客户管理');
  useSetCode(100);

  const requestRefresh = () => {
    setRefresh(true);
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const q = new URLSearchParams({ page, rows, keyword, checked });
        const resp = await get('/member/list?' + q.toString());
        setList(resp.list || []);
        setCount(resp.count || 0);
      } catch (err) {
        enqueueSnackbar(err.message);
      } finally {
        setLoading(false);
        setRefresh(false);
      }
    })();
  }, [enqueueSnackbar, page, rows, keyword, checked, refresh]);

  // 搜索
  const onKeywordChange = value => {
    setKeyword(value);
    setPage(0);
  }

  // 页面改变
  const onPageChange = (e, newPage) => {
    setPage(newPage);
  }

  // 每页行数改变
  const onRowsPerPageChange = e => {
    const rows = parseInt(e.target.value, 10);

    setRows(rows);
    setPage(0);
    setPageData('rowsPerPage', rows);
  }

  // 审核状态
  const onCheckedChange = (e, v) => {
    if (v !== null) {
      setChecked(v);
      setPage(0);
    }
  }

  return (
    <Container as='main' maxWidth='lg' sx={{ mb: 4 }}>
      <Toolbar sx={{ mt: 2 }} disableGutters>
        <Stack direction='row' spacing={2}>
          <SearchInput isLoading={loading} onChange={onKeywordChange}
            placeholder={count > 0 ? `在 ${count} 条记录中搜索...` : '搜索...'}
            sx={{ minWidth: 300 }}
          />
          <ToggleButtonGroup exclusive size='small' color='primary'
            value={checked} onChange={onCheckedChange}>
            <ToggleButton value='' sx={{ py: '4px' }}>全部</ToggleButton>
            <ToggleButton value='false' sx={{ py: '4px' }}>未审核</ToggleButton>
            <ToggleButton value='true' sx={{ py: '4px' }}>已审核</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Box flex={1} />
        <Button variant='outlined' size='small' LinkComponent={Link} to='level'>
          配置折扣规则
        </Button>
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding='checkbox'></TableCell>
            <TableCell align="center">姓名</TableCell>
            <TableCell align="center">公司</TableCell>
            <TableCell align="center">电话</TableCell>
            <TableCell align="center">邮箱</TableCell>
            <TableCell align="center">折扣规则</TableCell>
            <TableCell align="center">税率</TableCell>
            <TableCell align="center">注册时间</TableCell>
            <TableCell align="center" padding='checkbox'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => (
            <TableRow key={index} hover>
              <TableCell align="center" padding='checkbox'>
                {row.checked ?
                  <Stack justifyContent='center' alignItems='center'>
                    <Tooltip title='已通过审核' arrow>
                      <CheckIcon fontSize='small' color='success' />
                    </Tooltip>
                  </Stack>
                  :
                  <Check item={row} requestRefresh={requestRefresh} />
                }
              </TableCell>
              <TableCell align="center">{row.lastname}</TableCell>
              <TableCell align="center">{row.company}</TableCell>
              <TableCell align="center">{row.tel}</TableCell>
              <TableCell align="center">{row.email}</TableCell>
              <TableCell align="center">{row.level_name?.String}</TableCell>
              <TableCell align="center">{row.tax_rate}%</TableCell>
              <TableCell align="center">
                <TimeAgo time={row.create_at} variant='body2' />
              </TableCell>
              <TableCell align="center" padding='checkbox'>
                <MoreMenu item={row} requestRefresh={requestRefresh} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              colSpan={10}
              count={count}
              rowsPerPage={rows}
              page={page}
              SelectProps={{ inputProps: { 'aria-label': '每页行数' } }}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Container>
  )
}
